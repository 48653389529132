import "core-js/features/array/is-array";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/includes";
import "core-js/features/array/from";
import "core-js/features/string/starts-with";
import "core-js/features/string/includes";
import "core-js/features/object/assign";
import "core-js/features/object/keys";
import "core-js/features/object/values";
import "core-js/features/url";
import axios from "axios";

// eslint-disable-next-line no-extend-native
Array.prototype.isEmpty = function() {
  return this.length === 0;
};

// eslint-disable-next-line no-extend-native
Array.prototype.isNotEmpty = function() {
  return !this.isEmpty();
};

// eslint-disable-next-line no-extend-native
Array.prototype.randomIndex = function() {
  return Math.floor(this.length * Math.random());
};

// eslint-disable-next-line no-extend-native
Array.prototype.random = function() {
  return this[this.randomIndex()];
};

// eslint-disable-next-line no-extend-native
Array.prototype.count = function(predicate, thisArg) {
  return this.filter(predicate, thisArg).length;
};

// eslint-disable-next-line no-extend-native
Array.prototype.first = function() {
  return this.length > 0 ? this[0] : null;
};

// eslint-disable-next-line no-extend-native
Array.prototype.last = function() {
  return this.length > 0 ? this[this.length - 1] : null;
};

// eslint-disable-next-line no-extend-native
Array.prototype.shuffle = function() {
  const source = this.slice();
  const dest = [];

  while (source.length > 0) {
    const items = source.splice(source.randomIndex(), 1);
    dest.push(items[0]);
  }

  return dest;
};

// eslint-disable-next-line no-extend-native
Array.prototype.max = function(predicate) {
  let max = -Infinity;
  for (let i = 0; i < this.length; i++) {
      const itemMax = predicate(this[i]);
      if (itemMax > max) {
        max = itemMax;
      }
  }

  return max;
};

// eslint-disable-next-line no-extend-native
Array.prototype.min = function(predicate) {
  let min = Infinity;
  for (let i = 0; i < this.length; i++) {
      const itemMin = predicate(this[i]);
      if (itemMin < min) {
        min = itemMin;
      }
  }

  return min;
};

require("./config");
require("./sentry");
require("./webview");
require("./utils/ga");

window.axios = axios.create();
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["X-Client-Token"] = window.clientConfig.token;
window.axios.defaults.headers.common["X-Project-Key"] = window.appConfig.project.key;

window.appGlobalCache = {};

window.sessionStorage.clear();

require("./app");