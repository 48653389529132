import React from "react";
import AppContext from "../contexts/AppContext";
import ErrorView from "../components/ErrorView";

export default class ErrorPage extends React.Component {

  componentDidMount() {
    this.context.hideLoader();
  }

  render() {
    return <ErrorView error={{type: "photolab", code: 1}} />;
  }
}

ErrorPage.contextType = AppContext;