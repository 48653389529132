import Creative from "../Creative";
import {
  defaultHandlerCatch,
  defaultHandlerResolver,
  waitCreativeOrCancel,
  waitTaskHelper
} from "./helpers";
import * as api from "../../utils/api";

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export default (processing, creative) => new Promise((resolve, reject) => {
  const creatives = processing.creatives
    .filter((c) => c.group === creative.group)
    .filter((c) => !c.getExtra(Creative.EXTRA_IS_COLLAGE))
    .slice(0, 4);

  Promise.all(creatives.map((c) => waitCreativeOrCancel(c)))
    .then((creatives) => {
      const contentUrls = creatives.map((c) => c.getFile("raw"));

      return api.createTask("npp_collage_v1", {content_urls: contentUrls, lang: window.clientConfig.lang});
    })
    .then((taskResult) => waitTaskHelper(creative, "npp_collage", taskResult, 1000))
    .then((tasksResults) => {
      const resultUrl = tasksResults.result[0].url;

      creative.setFile("raw", resultUrl);
      creative.setFile("complex", tasksResults.result[1].url);
      creative.setFile("share_raw", tasksResults.result[2].url);
      creative.setFile("share_complex", tasksResults.result[3].url);
      creative.markAsProcessed(resultUrl);
    })
    .then(defaultHandlerResolver(creative, resolve))
    .catch(defaultHandlerCatch(creative, reject));
});