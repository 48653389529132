module.exports = {
  "strings": {
    "try_another_photo": "Другое фото",

    "time_next_try": "⏰ Повторите попытку через {{time}}",

    "safe_and_cool": "Помогите нам в <span>борьбе с ложной информацией!</span>",

    "landing__title_1": "Ваш уникальный редактор аватарок.",
    "landing__title_2": "Новые стили каждую неделю. Создано AI",
    "landing__subtitle": "Мир вокруг постоянно меняется. Меняться должна и ваша аватарка!",
    "landing__get_the_app_button": "Скачать приложение",

    "choose_photo": "Выберите фото",

    "choose_hide_text": "Скрыть описание",
    "choose_show_text": "Показать описание",

    "modal__title": "Отличный выбор!",
    "modal__subtitle": "Теперь обновите аватарку, чтобы друзья и подписчики завидовали белой завистью ;)",

    "btn_creative_refresh": "ещё {{num}}",

    "main_info_title": "Понравилось 100К+ людям / 100К+ лайков",
    "main_info_text": "Удивляйте друзей и подписчиков новыми фото профиля, которые полностью отражают ваше настроение.",

    "rules_list_title": "Правила просты:",
    "rules_list_item1": "Выберите аватарку под своё настроение",
    "rules_list_item2": "и возвращайтесь через неделю за новыми эффектами, возможностями и новой аватаркой.",

    "processing_tip": "Cовет {{item}}/{{total}}",
    "processing_text_1": "Вы меняетесь - меняется и ваша аватарка!  🙈",
    "processing_text_2": "Больше никаких правил - обновляйте аватарку так часто, как хотите! 😎",
    "processing_text_3": "Выберите обработку, отражающую ваше настроение! 🤩",
    "processing_text_4": "Удивляйте друзей своим новым профилем каждый раз 👍",
    "processing_text_5": "Возвращайтесь за новыми идеями через несколько дней  😉",

    "processing2_0": "Больше никаких правил! Делайте по-своему:",
    "processing2_1": "Обновляйте аватрку каждые два-три дня 🔄",
    "processing2_2": "Наслаждайтесь повышенным вниманием к вашему профилю 😎",
    "processing2_3": "Возвращайтесь за новыми эффектами и обработками 🤩",
    "processing2_4": "Повторите 🚀",

    "resubscribe_text1": "&nbsp;",
    "resubscribe_text2": "Кажется, у нас проблема...",
    "resubscribe_text3": "Вы отменили подписку и теперь все Premium функции снова недоступны.<br />Возобновите подписку, чтобы вернуть к ним доступ.",

    "maskeditor__title": "Изменить область фигуры",
    "maskeditor__subtitle": "Приближайте, прокручивая колёсико мыши. Нажмите и не отпускайте, чтобы перемещаться по снимку",

    "btn_undo": "Отменить",
    "btn_redo": "Повторить",
    "btn_save": "Сохранить",
    "size": "Размер",
    "btn_get": "Загрузить бесплатно",

    "result__creative_download": "Скачать",
    "result__creative_download_webview": "Сохранить и поделиться",
    "result__creative_retry": "Попробовать ещё раз",
    "result__creative_failed_message": "Ой, со стилем что-то пошло не так. 😔 Примените любой другой или попробуйте снова.",
    "result__choose_title": "Не можете выбрать?",
    "result__choose_text": "Спросите подписчиков, опубликовав коллаж с разными обработками",

    "rateapp_dialog__title": "Любите <span>#NewProfilePic</span>?",
    "rateapp_dialog__message": "Оцените приложение, чтобы другие пользователи смогли найти его и полюбить!",
    "rateapp_dialog__rate_button": "Оценить!",
    "rateapp_dialog__skip_button": "Закрыть",

    "ad_tab_title": "Еще больше крутых эффектов в нашем приложении <span>Photo Lab</span>",

    "error__error": "Ошибка",
    "modal_button": "Понятно",

    "remove_logo_modal__title": "С или без... Вот в чём вопрос",
    "remove_logo_modal__message": "А вы знали, что логотип является частью дизайна этого эффекта? Мы будем рады, если вы его оставите.",
    "remove_logo_modal__btn_cancel": "Оставить лого",
    "remove_logo_modal__btn_remove": "Убрать лого",

    "toggle_watermark_tooltip__text": "Коснитесь лого, чтобы убрать его",
    "toggle_watermark_on": "С лого",
    "toggle_watermark_off": "Без лого",

    "internal_error": "Произошла ошибка...",
    "error__network_message": "Пожалуйста, проверьте Интернет-соединение.",
    "error__default_message": "Что-то пошло не так.",
    "error__api_code_1": "Возникла внутренняя ошибка",
    "error__api_code_2": "Неверные параметры",
    "error__api_code_3": "Во время обработки произошла ошибка",
    "error__api_code_401": "Неавторизованный запрос",
    "error__api_code_404": "Не найдено",
    "error__api_code_410": "Запрашиваемый контент удален",
    //"error__api_code_415": "Неподдерживаемый формат файла",

    "error_overload": "Нам очень жаль, но у нас возросли нагрузки на сервера в связи с большим количеством новых установок приложения. Пожалуйста, повторите попытку позже. Мы постараемся починить всё как можно скорее.",

    "timer__title": "Новые стили через:",
    "timer__expired_text_landing": "Выберите фото и попробуйте новые стили",
    "timer__expired_text_result": "Выберите фото и попробуйте новые стили:",

    "timer__days_one": "день",
    "timer__days_few": "дня<br/>&nbsp;",
    "timer__days_many": "дней<br/>&nbsp;<br/>&nbsp;",
    "timer__hours_one": "час",
    "timer__hours_few": "часа<br/>&nbsp;",
    "timer__hours_many": "часов<br/>&nbsp;<br/>&nbsp;",
    "timer__minutes_one": "минута",
    "timer__minutes_few": "минуты<br/>&nbsp;",
    "timer__minutes_many": "минут<br/>&nbsp;<br/>&nbsp;",
    "timer__seconds_one": "секунда",
    "timer__seconds_few": "секунды<br/>&nbsp;",
    "timer__seconds_many": "секунд<br/>&nbsp;<br/>&nbsp;",

    "contact_us": "Напишите нам",

    "btn_full_size_results_v1": "Новинка! <span>Результаты без кропа</span>",
    "btn_full_size_results_v2": "Новинка! Результаты без кропа",
  },
};

