import Creative from "../Creative";
import md5 from "md5";
import {
  defaultHandlerCatch,
  defaultHandlerResolver,
  waitCreativeOrCancel, waitTaskHelper
} from "./helpers";
import * as api from "../../utils/api";

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export default (processing, creative) => {
  if (!creative.hasExtra(Creative.EXTRA_CHILDREN_IDS)) {
    const creativesIds = processing.groups
      .filter((g) => g !== creative.group)
      .slice(0, 4)
      .map((g) => processing.getSelectedCreativeInGroup(g).id);

    creative.setExtra(Creative.EXTRA_CHILDREN_IDS, creativesIds);
    creative.setExtra(Creative.EXTRA_CHILDREN_HASH, md5(creativesIds.join(":")));
  }

  return new Promise((resolve, reject) => {
    const creativesIds = creative.getExtra(Creative.EXTRA_CHILDREN_IDS);
    const creatives = creativesIds.map((id) => processing.creatives.find((c) => c.id === id));

    Promise.all(creatives.map((c) => waitCreativeOrCancel(c)))
      .then((creatives) => {
        const contentUrls = creatives.map((c) => c.getFile("raw"));

        return api.createTask("npp_collage_v1", {content_urls: contentUrls, lang: window.clientConfig.lang});
      })
      .then((taskResult) => waitTaskHelper(creative, "npp_collage", taskResult, 1000))
      .then((tasksResults) => {
        const resultUrl = tasksResults.result[0].url;

        creative.setFile("raw", resultUrl);
        creative.setFile("complex", tasksResults.result[1].url);
        creative.setFile("share_raw", tasksResults.result[2].url);
        creative.setFile("share_complex", tasksResults.result[3].url);
        creative.markAsProcessed(resultUrl);
      })
      .then(defaultHandlerResolver(creative, resolve))
      .catch(defaultHandlerCatch(creative, reject));
  });
}
