import React, {Component} from 'react';
import {webviewCall} from "../utils/webview";
import {hitEvent, hits, logEventNative, userNativeEvents} from "../utils/log";
import ContinueWithAppModal from "./ContinueWithAppModal";
import AppContext from "../contexts/AppContext";
import clientStorage from "../utils/client-storage";

export default class FileChooseButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      image: null,
    };

    this.fileFieldRef = React.createRef();

    this.handleBrowserFileSelected = this.handleBrowserFileSelected.bind(this);
    this.handleWebviewFileSelected = this.handleWebviewFileSelected.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleError = this.handleError.bind(this);
    this.triggerSelectFile = this.triggerSelectFile.bind(this);
    this.logFileSelected = this.logFileSelected.bind(this);
  }

  componentDidMount() {
    window.webviewEventsListeners.photoSelected.setListener(this.handleWebviewFileSelected);
  }

  componentWillUnmount() {
    window.webviewEventsListeners.photoSelected.removeListener();
  }

  triggerSelectFile() {
    if (window.clientConfig.isWebview) {
      const params = {
        func: "onNativeAppPhotoSelected",
        use_crop: 0,
        num_photos: 1,
        show: "gallery",
        tab: "faces",
        neurocamera: 1,
      };

      webviewCall("nativePhotoSelect", params);
    } else {
      if (this.fileFieldRef.current) {
        this.fileFieldRef.current.value = "";
        this.fileFieldRef.current.click();
      }
    }
  }

  handleError(code, message) {
    this.props.onError && this.props.onError(code, message);
  }

  logFileSelected() {
    hitEvent(hits.PHOTO_SELECT);

    if (window.clientConfig.isNew) {
      if (window.clientConfig.splitGroupId === 1) {
        hitEvent(hits.PHOTO_SELECT1, 1, true);
      } else if (window.clientConfig.splitGroupId === 3) {
        hitEvent(hits.PHOTO_SELECT2, 1, true);
      }
    } else {
      //hitEvent(hits.PHOTO_SELECT_OLD, 1, true);
    }
  }

  handleBrowserFileSelected(file) {
    this.logFileSelected();
    this.props.onFileSelected && this.props.onFileSelected(file);
  }

  handleWebviewFileSelected(data) {
    if (!data || !data.photos || data.photos.length === 0) {
      return;
    }

    const photo = data.photos.pop();

    this.logFileSelected();

    const imageUrl = photo.image_url.replace("http://", "https://");
    this.props.onFileSelected && this.props.onFileSelected(imageUrl);
  }

  handleClick(e) {
    logEventNative(userNativeEvents.buttonTapped, [
      "choose_photo",
      this.props.place || ""
    ]);

    if (window.clientConfig.isWebMobileDesktopMode) {
      const limit = window.appConfig.processings.mobileDesktopModePhotosLimit;
      if (clientStorage.getProcessedPhotosAmount() >= limit) {
        this.context.pushModal(<ContinueWithAppModal
          key="FileChooseButton_ContinueWithAppModal"
        />);
        return;
      }
    }

    this.props.onClick && this.props.onClick(e);
    this.triggerSelectFile();
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    return <React.Fragment>
      <button
        disabled={this.props.disabled}
        className={this.props.className}
        onClick={this.handleClick.bind(this)}>
        <span
          hidden={this.props.progress === 0}
          className="effects"
          style={{width: (this.props.progress*100) + "%"}}>
            <span children={this.props.progressContent || ""} />
        </span>
        {this.props.children}
      </button>

      <input
        className="file-field-hidden"
        type="file"
        accept="image/*"
        ref={this.fileFieldRef}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => this.handleBrowserFileSelected(e.target.files[0])} />
    </React.Fragment>;
  }
}

FileChooseButton.contextType = AppContext;