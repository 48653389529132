import React from "react";
import {assetUrl} from "../../utils/etc";
import i18n from "../../i18n";
import Slider from "react-slick";
import {webviewOpenBrowser} from "../../utils/webview";

const sliderSettings = {
  autoplay: true,
  autoplaySpeed: 3000,
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
  responsive: [
    {
      breakpoint: 720,
      settings: {centerPadding: '20px'}
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        centerPadding: '120px'
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        centerPadding: '80px'
      }
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1,
        centerPadding: '60px'
      }
    },
    {
      breakpoint: 340,
      settings: {
        slidesToShow: 1,
        centerPadding: '40px'
      }
    }
  ],
};

const PHOTOLAB_LINK_ANDROID = "https://play.google.com/store/apps/details?id=vsin.t16_funny_photo";
const PHOTOLAB_LINK_IOS = "itms-apps://itunes.apple.com/app/id441457218";
const PHOTOLAB_LINK_DESKTOP = "https://photolab.me/";

export const SLIDES = [1, 2, 3, 4, 5, 6];

export default class PhotolabAdTabContent extends React.Component {
  handleGetAppClick = () => {
    let photolabLink = PHOTOLAB_LINK_DESKTOP;

    if (window.clientConfig.isWebviewAndroid || window.clientConfig.isWebMobileAndroid) {
      photolabLink = PHOTOLAB_LINK_ANDROID;
    } else if (window.clientConfig.isWebviewIOS || window.clientConfig.isWebMobileIOS) {
      photolabLink = PHOTOLAB_LINK_IOS;
    }

    if (window.clientConfig.isWebview) {
      webviewOpenBrowser(photolabLink);
    } else {
      window.open(photolabLink, "_blank");
    }
  }

  render() {
    return <div className="result-content">
      <h2 className="result-content-title" dangerouslySetInnerHTML={{__html: i18n.t("ad_tab_title")}} />

      <Slider {...sliderSettings}>
        {SLIDES.map((number) => <SlideView key={number} number={number} />)}
      </Slider>

      <div className="btns-container">
        <button onClick={this.handleGetAppClick} className="btn-get" dangerouslySetInnerHTML={{__html: i18n.t("btn_get")}} />
      </div>
    </div>;
  }
}

export function getSrcSet(number, extension) {
 return [
   // assetUrl(`assets/images/slider2/${number}/${number}.${extension}`) + " 1x",
   // assetUrl(`assets/images/slider2/${number}/${number}@2x.${extension}`) + " 2x",
   assetUrl(`assets/images/slider2/${number}/${number}@3x.${extension}`)
 ].join(", ");
}

export function SlideView({number}) {
  const jpg = getSrcSet(number, "jpg");
  const webp = getSrcSet(number, "webp");

  return <div className="slide-container">
    <div className="slide">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={jpg} alt="" />
      </picture>
    </div>
  </div>;
}