/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {
  combo, stepWithFallback, setCreativesGroup,
  setCreativesHiddenAfterAt,
  setCreativesVisibleAfterAt,
  whenSplit, whenSplitInRange, getCreativesInRandomOrder, skipOnMultifaceError
} from "./helpers";
import {pwAssetUrl} from "../../utils/etc";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";

const isShowNewImmediately = whenSplitInRange(6, 10);
const firstRolloutDate = window.appConfig.isTestInstance
  ? new Date().getTime()
  : new Date("2022-09-16T13:15:00+03:00").getTime();

const rolloutDates = [
  firstRolloutDate,
  isShowNewImmediately ? firstRolloutDate : new Date("2022-06-24T23:00:00+03:00").getTime(),
  isShowNewImmediately ? firstRolloutDate : new Date("2022-06-25T05:00:00+03:00").getTime(),
  isShowNewImmediately ? firstRolloutDate : new Date("2022-06-25T11:00:00+03:00").getTime(),
  isShowNewImmediately ? firstRolloutDate : new Date("2022-06-25T17:00:00+03:00").getTime(),
];

function getGroups() {
  let list = [
    groups.CELEBRITY_2,
    groups.GLOSS,
    groups.TAB3,
    groups.RETOUCH,
    groups.CELEBRITY,
  ];

  if (window.clientConfig.isIndiaRegionUser) {
    const celebPos = list.indexOf(groups.CELEBRITY);
    if (celebPos > 0) {
      list.splice(celebPos, 1);
      list.unshift(groups.CELEBRITY);
    }
  }

  return list;
}

function getCommonCreatives() {
  return [
    new CreativeConfig(groups.COMMON, "gender", handlersNames.GENDER),
  ];
}

export function getFullSizeCreatives() {
  return setCreativesGroup(groups.FULL_SIZE, [
    combo([7088]),
  ]);
}

function getCelebrityCreatives() {
  return setCreativesGroup(groups.CELEBRITY, [
    combo([6935, 6874]).setPreviewUrl(pwAssetUrl("npp/previews/6843.jpg")),
    combo([6935, 6878]).setPreviewUrl(pwAssetUrl("npp/previews/6840.jpg")),
    combo([6756]),
  ]);
}

function getCelebrity2Creatives() {
  return setCreativesGroup(groups.CELEBRITY_2, [
    ...[
      // celebrity2/6455_7116 вообще все время для новых остается дефолтом
      // вплоть до момента пока я не скажу обратное
      combo([7333, 7116]).setSelected(window.clientConfig.isNew || window.clientConfig.isIndiaRegionUser),
      combo([6756, 7335]).setSelected(window.clientConfig.isOld && !window.clientConfig.isIndiaRegionUser),
      combo([6756, 7240]).setExtra(Creative.EXTRA_POSITION, 2),
      combo([6756, 7215]).setExtra(Creative.EXTRA_POSITION, 3),
      combo([6756, 7251]),
      combo([6756, 7272]),
    ],
    ...setCreativesVisibleAfterAt(firstRolloutDate, []),
    ...setCreativesHiddenAfterAt(firstRolloutDate, []),
  ]);
}

function getGlossCreatives() {
  const comboForIndia = combo([stepWithFallback(7083, 7084), 7186]);
  const comboForOthers = combo([stepWithFallback(7083, 7084), 7271]);

  return setCreativesGroup(groups.GLOSS, [
    ...[
      window.clientConfig.isIndiaRegionUser ? comboForIndia : comboForOthers,
      combo([stepWithFallback(7083, 7084), 7253]).setSelected(window.clientConfig.isNew),
      combo([stepWithFallback(7083, 7084), 7336]).setSelected(window.clientConfig.isOld),
      combo([stepWithFallback(7083, 7084), 7282]),
      combo([stepWithFallback(7083, 7084), 7330]),
      combo([stepWithFallback(7083, 7084), 7311]),
    ],
    ...setCreativesVisibleAfterAt(firstRolloutDate, []),
    ...setCreativesHiddenAfterAt(firstRolloutDate, []),
  ]);
}

function getTab3Creatives() {
  return setCreativesGroup(groups.TAB3, [
    ...[
      combo([7019, 7038]).setSelected(window.clientConfig.isIndiaRegionUser),
      combo([7019, 7319]).setSelected(!window.clientConfig.isIndiaRegionUser),
      combo([7019, 7270]),
      combo([7019, 7316]),
      combo([7019, 7293]),
      combo([7019, 7337]),
    ],
    ...setCreativesVisibleAfterAt(firstRolloutDate, []),
    ...setCreativesHiddenAfterAt(firstRolloutDate, []),
  ]);
}

function getRetouchCreatives() {
  return setCreativesGroup(groups.RETOUCH, [
    ...[
      combo([7333, skipOnMultifaceError(6472), 4652, 2116, 3734, 2218, 2218])
        .setSelected(window.clientConfig.isNew && !window.clientConfig.isIndiaRegionUser),
      combo([7333, skipOnMultifaceError(6472), 4652, 2176, 2348, 2218])
        .setSelected(window.clientConfig.isOld && !window.clientConfig.isIndiaRegionUser),
      combo([7333, skipOnMultifaceError(6472), 5076, 1850, 3694, 1826])
        .setSelected(window.clientConfig.isIndiaRegionUser)
        .setExtra(Creative.EXTRA_POSITION, window.clientConfig.isIndiaRegionUser ? -1 : 1),
      combo([7333, skipOnMultifaceError(6472), 1850, 5076, 7301, 2570, 2345]),
      combo([7333, skipOnMultifaceError(6472), 5076, 2811, 2345]),
      combo([7333, skipOnMultifaceError(6472), 2275, 2176, 1850, 3105, 2344, 3734]),
    ],
    ...setCreativesVisibleAfterAt(firstRolloutDate, []),
    ...setCreativesHiddenAfterAt(firstRolloutDate, []),
  ]);
}

function getArtCreatives() {
  return setCreativesGroup(groups.ART, [
    ...[
      combo([6455, 2603, 2345]),
      combo([6455, 2167, 2291, 2341]),
      combo([6455, 2375, 2345]),
      combo([6455, 2560, 1765, 1111, 3734]),
      combo([6455, 1850, 5076, 2765, 2570, 2345]),
      combo([6455, 1323, 1185, 1185, 2351, 7263]),
    ],
    ...setCreativesVisibleAfterAt(firstRolloutDate, []),
    ...setCreativesHiddenAfterAt(firstRolloutDate, []),
  ]);
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function() {
    let listOfAll = [
      ...getCelebrityCreatives(),
      ...getCelebrity2Creatives(),
      ...getGlossCreatives(),
      ...getRetouchCreatives(),
      ...getTab3Creatives(),
      ...getArtCreatives(),
    ];

    if (window.clientConfig.features.tabsWithRandomOrder) {
      listOfAll = [
        ...getCelebrityCreatives(),
        ...getCreativesInRandomOrder(getCelebrity2Creatives()),
        ...getCreativesInRandomOrder(getGlossCreatives()),
        ...getCreativesInRandomOrder(getRetouchCreatives()),
        ...getCreativesInRandomOrder(getTab3Creatives()),
        ...getCreativesInRandomOrder(getArtCreatives()),
      ];
    }

    return [
      ...getCommonCreatives(),
      ...listOfAll,
    ];
  },
};
