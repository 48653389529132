import React from "react";
import AppContext from "../contexts/AppContext";
import {debounce} from "../utils/etc";
import i18n from "../i18n";
import {photolabTask} from "../photolab/api";
import PhotolabTaskBuilder from "../photolab/PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../photolab/PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../photolab/PhotolabTaskImageUrl";
import * as api from "../utils/api";
import routes from "../routes";
import HomeButton from "../components/HomeButton";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {goToProcessing} from "../utils/url";
import {fileToJson, webviewCallWithCallback} from "../utils/webview";
import ErrorView from "../components/ErrorView";

function containRectToBounds(rect, bounds) {
  const rectRatio = rect.width / rect.height;
  const boundsRatio = bounds.width / bounds.height;
  const box = {};

  if (rectRatio > boundsRatio) {
    box.width = bounds.width;
    box.height = rect.height * (bounds.width / rect.width);
  } else {
    box.width = rect.width * (bounds.height / rect.height);
    box.height = bounds.height;
  }

  box.x = (bounds.width - box.width) / 2;
  box.y = (bounds.height - box.height) / 2;

  return box;
}

function promisifyImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (e) => reject([e, image]);
    image.src = url;
  });
}

const viewModes = {
  over: "over",
  preview: "preview",
};

const tools = {
  pen: "pen",
  eraser: "eraser",
};

const toolSizeConfig = {
  min: 5,
  max: 100,
  step: 1,
  defaultValue: 50,
};

const maskColor = "#01e777";
const maxHistoryEntries = 15;

export default class MaskEditorPage extends React.Component {

  state = {
    error: null,
    isAfterResult: false, // смена маски инициирована со страницы результата
    sourceFileUrl: null,
    maskFileUrl: null,
    viewMode: viewModes.over,
    tool: tools.pen,
    toolSize: toolSizeConfig.defaultValue,
    toolSizeIndicatorIsHidden: true,
    history: [],
    historyIndex: 0,
    cursorIsHidden: true,
    cursorPosition: {
      x: 0,
      y: 0,
    },
  };

  constructor(props) {
    super(props);

    this.canvasHolder = null;
    this.cursor = null;
    this.lowerCanvas = null;
    this.lowerCanvasCtx = null;
    this.upperCanvas = null;
    this.upperCanvasCtx = null;
    this.photoImage = null;
    this.maskImage = null;
    this.zoom = 1;
    this.minZoom = 1;
    this.maxZoom = 10;
    this.offsetX = 0;
    this.offsetY = 0;
    this.touches = [];
  }

  componentDidMount() {
    logEvent(userEvents.PAGE, {page: "maskeditor"});

    const currentURL = new URL(window.location.href);

    if (currentURL.searchParams.has("fileUrl")) {
      this.context.showLoader(false, currentURL.searchParams.get("fileUrl"));

      this.startWithFileUrl(
        currentURL.searchParams.get("fileUrl"),
        currentURL.searchParams.get("maskUrl")
      );
    } else if (this.props.location.state && this.props.location.state.file) {
      this.context.showLoader(false, this.props.location.state.file.url);

      this.startWithFileUrl(
        this.props.location.state.file.url,
        this.props.location.state.mask && this.props.location.state.mask.url
      );
    } else {
      this.props.history.replace(routes.INDEX);
    }

    const isAfterResult = !!(this.props.location.state && this.props.location.state.backTo === "result");

    this.setState({isAfterResult});

    if (isAfterResult) {
      hitEvent(hits.PAGE_MASK_AFTER_RESULT);
    } else {
      hitEvent(hits.PAGE_MASK_BEFORE_PROCESSING);
    }

    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    document.body.removeEventListener("mouseup", this.handleDocumentMouseUp);
    document.body.removeEventListener("mousewheel", this.handleDocumentMouseWheel);
  }

  startWithFileUrl(sourceFileUrl, maskFileUrl) {
    this.setState({sourceFileUrl});

    if (typeof maskFileUrl === "string" && maskFileUrl.length > 0) {
      this.handleMaskReceived(sourceFileUrl, maskFileUrl);
      return;
    }

    const maskTaskConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod("nn_mod_net"))
      .addImage(new PhotolabTaskImageUrl(sourceFileUrl))
      .build()

    photolabTask(maskTaskConfig)
      .then((taskResult) => {
        this.handleMaskReceived(sourceFileUrl, taskResult.resultUrl);
      })
      .catch((error) => {
        this.setState({
          error: error
        }, this.context.hideLoader);
      });
  }

  handleMaskReceived = (sourceFileUrl, maskFileUrl) => {
    this.setState({maskFileUrl});

    if (window.clientConfig.isWebview) {
      webviewCallWithCallback("editMask", {
        "src[0]": encodeURIComponent(sourceFileUrl),
        "mask[0]": encodeURIComponent(maskFileUrl),
      }, this.handleWebviewUpdateMask);

      return;
    }

    api.imageEncodeAsDataUrl(maskFileUrl)
      .then((maskFile) => Promise.all([
        promisifyImage(sourceFileUrl),
        promisifyImage(maskFile),
      ]))
      .then((imagesResults) => {
        this.photoImage = imagesResults[0];
        this.maskImage = imagesResults[1];

        this.context.hideLoader(this.initCanvases);
      });
  };

  handleWebviewUpdateMask = (data) => {
    if (data === null && this.state.isAfterResult) {
      this.props.history.replace(routes.RESULT);
      return;
    }

    let maskUrl = this.state.maskFileUrl;

    if (data && data.length > 0) {
      maskUrl = data[0]["0"];
    }

    hitEvent(hits.MASK_UPDATED);

    goToProcessing(
      this.props.history,
      this.props.location.state.file,
      fileToJson(maskUrl),
      this.state.isAfterResult
    );
  }

  initCanvases = () => {
    if (!this.canvasHolder) {
      console.error("Cannot init canvas: field canvasHolderRef is empty.");
      return;
    }

    this.holderBounds = this.canvasHolder.getBoundingClientRect();

    this.photoCanvas = document.createElement("canvas");
    this.photoCanvas.width = this.photoImage.width;
    this.photoCanvas.height = this.photoImage.height;
    this.photoCanvasCtx = this.photoCanvas.getContext("2d");
    this.photoCanvasCtx.drawImage(this.photoImage, 0, 0);

    this.maskCanvas = document.createElement("canvas");
    this.maskCanvas.width = this.photoImage.width;
    this.maskCanvas.height = this.photoImage.height;
    this.maskCanvasCtx = this.maskCanvas.getContext("2d");

    this.lowerCanvas.width = this.holderBounds.width;
    this.lowerCanvas.height = this.holderBounds.height;
    this.lowerCanvasCtx = this.lowerCanvas.getContext("2d");

    document.body.addEventListener("mouseup", this.handleDocumentMouseUp);
    document.body.addEventListener("mousewheel", this.handleDocumentMouseWheel, {passive: false});

    this.upperCanvas.width = this.holderBounds.width;
    this.upperCanvas.height = this.holderBounds.height;
    this.upperCanvas.addEventListener("mousedown", this.handleCanvasMouseDown);
    this.upperCanvas.addEventListener("mousemove", this.handleCanvasMouseMove);
    this.upperCanvas.addEventListener("mouseleave", this.handleCanvasMouseLeave);
    this.upperCanvas.addEventListener("touchstart", this.handleTouchStart, {passive: false});
    this.upperCanvas.addEventListener("touchend", this.handleTouchEnd, {passive: false});
    this.upperCanvas.addEventListener("touchmove", this.handleTouchMove, {passive: false});
    this.upperCanvasCtx = this.upperCanvas.getContext("2d");

    this.photoBounds = containRectToBounds(
      {width: this.photoCanvas.width, height: this.photoCanvas.height},
      {width: this.upperCanvas.width, height: this.upperCanvas.height},
    );

    this.maskBounds = containRectToBounds(
      {width: this.maskCanvas.width, height: this.maskCanvas.height},
      {width: this.upperCanvas.width, height: this.upperCanvas.height},
    );

    this.offsetX = this.photoBounds.x;
    this.offsetY = this.photoBounds.y;

    if (this.maskImage) {
      this.maskCanvasCtx.drawImage(this.maskImage, 0, 0);

      const imageData = this.maskCanvasCtx.getImageData(0, 0, this.maskCanvas.width, this.maskCanvas.height);
      const data32 = new Uint32Array(imageData.data.buffer);
      let i = 0;
      while(i < data32.length) data32[i] = data32[i++] << 8;

      this.maskCanvasCtx.putImageData(imageData, 0, 0);

      this.maskCanvasCtx.save();
      this.maskCanvasCtx.globalCompositeOperation = "source-in";
      this.maskCanvasCtx.fillStyle = maskColor;
      this.maskCanvasCtx.fillRect(0, 0, this.maskCanvas.width, this.maskCanvas.height);
      this.maskCanvasCtx.restore();

      this.initMaskImageData = this.maskCanvasCtx.getImageData(0, 0, this.maskCanvas.width, this.maskCanvas.height);

      this.redrawUpperCanvas();
    }

    this.redrawLowerCanvas();
    this.storeMaskState();
  }

  handleWindowResize = () => debounce("MaskEditorPage_handleWindowResize", 100, () => {
    if (!this.upperCanvas || !this.lowerCanvas) {
      return;
    }

    // to level canvas holder
    this.upperCanvas.height = 10;
    this.lowerCanvas.height = 10;

    this.holderBounds = this.canvasHolder.getBoundingClientRect();

    this.lowerCanvas.width = this.holderBounds.width;
    this.lowerCanvas.height = this.holderBounds.height;

    this.upperCanvas.width = this.holderBounds.width;
    this.upperCanvas.height = this.holderBounds.height;

    this.photoBounds = containRectToBounds(
      {width: this.photoCanvas.width, height: this.photoCanvas.height},
      {width: this.upperCanvas.width, height: this.upperCanvas.height},
    );

    this.maskBounds = containRectToBounds(
      {width: this.maskCanvas.width, height: this.maskCanvas.height},
      {width: this.upperCanvas.width, height: this.upperCanvas.height},
    );

    this.zoom = this.minZoom;
    this.offsetX = this.photoBounds.x;
    this.offsetY = this.photoBounds.y;

    // todo
    this.redraw();
  });

  handleTouchEnd = (e) => {
    e.preventDefault();

    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = e.changedTouches[i];
      const index = this.touches.findIndex((t) => t.id === touch.identifier);
      if (index >= 0) {
        this.touches.splice(index, 1);
      }
    }

    if (this.shouldBeSaveOnTouchEnd) {
      this.shouldBeSaveOnTouchEnd = false;
      this.storeMaskState();
    }
  };

  handleTouchStart = (e) => {
    e.preventDefault();

    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = e.changedTouches[i];
      const x = touch.clientX - this.holderBounds.left;
      const y = touch.clientY - this.holderBounds.top;

      this.touches.push({
        id: touch.identifier,
        x: x,
        y: y,
        px: x,
        py: y,
        sx: x,
        sy: y,
      });
    }

    if (this.touches.length === 1) {
      if (this.preventPaintFlag) {
        return;
      }

      this.shouldBeSaveOnTouchEnd = true;
      this.shouldBeResetOnAnotherTouch = true;

      const touch = this.touches.find((t) => t.id === e.changedTouches[0].identifier);
      this.paintMask(touch.x, touch.y, touch.x, touch.y, this.state.tool, this.state.toolSize, true);
    } else { // если тачей стало больше 1 то отменяем сохранение изменений
      this.shouldBeSaveOnTouchEnd = false;

      // а также отменяем текущую рисовку
      if (this.shouldBeResetOnAnotherTouch) {
        this.shouldBeResetOnAnotherTouch = false;
        this.handleResetLastPaint();
      }
    }
  }

  handleTouchMove = (e) => {
    e.preventDefault();

    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = this.touches.find((t) => t.id === e.changedTouches[i].identifier);
      if (touch != null) {
        touch.px = touch.x;
        touch.py = touch.y;
        touch.x = e.changedTouches[i].clientX - this.holderBounds.left;
        touch.y = e.changedTouches[i].clientY - this.holderBounds.top;
      }
    }

    if (this.touches.length === 1) {
      if (this.preventPaintFlag) {
        return;
      }

      const touch = this.touches.find((t) => t.id === e.changedTouches[0].identifier);
      this.paintMask(touch.x, touch.y, touch.px, touch.py, this.state.tool, this.state.toolSize);
    } else if (this.touches.length === 2) {
      const touch1 = this.touches[0];
      const touch2 = this.touches[1];
      const cd = Math.hypot(touch1.x - touch2.x, touch1.y - touch2.y);
      const pd = Math.hypot(touch1.px - touch2.px, touch1.py - touch2.py);
      const dd = cd - pd;
      const dh1 = (touch1.x <= touch1.sx ? "l" : "r");
      const dv1 = (touch1.y <= touch1.sy ? "t" : "b");
      const dh2 = (touch2.x <= touch2.sx ? "l" : "r");
      const dv2 = (touch2.y <= touch2.sy ? "t" : "b");

      if (dh1 === dh2 && dv1 === dv2) {
        this.move(touch1.x - touch1.px, touch1.y - touch1.py);
      } else {
        const cx = window.clientConfig.isWebDesktop
          ? (this.holderBounds.width / 2)
          : (touch1.x + ((touch2.x - touch1.x) / 2));
        const cy = window.clientConfig.isWebDesktop
          ? (this.holderBounds.height / 2)
          : (touch1.y + ((touch2.y - touch1.y) / 2));
        this.updateZoom(dd * 0.1, cx, cy);
      }

      this.preventPaintFlag = true;
      debounce("MaskEdtior_preventPaintFlag", 300, () => {
        this.preventPaintFlag = false;
      });
    }
  };

  handleCanvasMouseDown = (e) => {
    this.documentMouseUp = e.button !== 0;
    this.canvasMouseDown = e.button === 0;
    this.canvasMouseDrag = e.button === 1;
    this.lastMouseX = e.clientX - this.holderBounds.left;
    this.lastMouseY = e.clientY - this.holderBounds.top;

    if (this.canvasMouseDown) {
      this.paintMask(this.lastMouseX, this.lastMouseY, this.lastMouseX, this.lastMouseY, this.state.tool, this.state.toolSize, true);
    }
  };

  handleDocumentMouseUp = (e) => {
    if (!this.upperCanvas || !this.lowerCanvas) {
      return;
    }

    this.documentMouseUp = e.button === 0;

    if (this.canvasMouseDown) {
      this.storeMaskState();
    }

    this.canvasMouseDown = false;
    this.canvasMouseDrag = false;
  };

  handleCanvasMouseLeave = (e) => {
    this.setState({cursorIsHidden: true});

    if (this.documentMouseUp) {
      if (this.canvasMouseDown) {
        this.storeMaskState();
      }

      this.canvasMouseDown = false;
      this.canvasMouseDrag = false;
    }
  };

  handleCanvasMouseMove = (e) => {
    const x = e.clientX - this.holderBounds.left;
    const y = e.clientY - this.holderBounds.top;

    this.setState({
      cursorIsHidden: false,
      cursorPosition: {
        x: x - this.state.toolSize/2,
        y: y - this.state.toolSize/2,
      },
    });

    if (this.canvasMouseDrag) {
      this.move(x - this.lastMouseX, y - this.lastMouseY);
    } else if (this.canvasMouseDown) {
      this.paintMask(x, y, this.lastMouseX, this.lastMouseY, this.state.tool, this.state.toolSize);
    }

    this.lastMouseX = x;
    this.lastMouseY = y;
  };

  handleDocumentMouseWheel = (e) => {
    if (!this.upperCanvas || !this.lowerCanvas) {
      return;
    }

    this.updateZoom(
      e.deltaY > 0 ? -1 : 1,
      e.clientX - this.holderBounds.left,
      e.clientY - this.holderBounds.top
    );
  };

  updateZoom = (direction, cx = Infinity, cy = Infinity) => {
    const prevZoom = this.zoom;

    if (this.zoom === this.maxZoom && direction > 0) {
      return;
    }

    const intensity = Math.exp(direction * 0.1);
    let nextZoom = prevZoom * intensity;
    nextZoom = Math.max(nextZoom, this.minZoom);
    nextZoom = Math.min(nextZoom, this.maxZoom);

    if (cx === Infinity && cy === Infinity) {
      this.zoom = nextZoom;
      this.redraw();
      return;
    }

    this.zoom = nextZoom;

    if (this.zoom < 1.3 && direction < 0) {
      this.offsetX = this.holderBounds.width/2 - (this.photoBounds.width * this.zoom)/2;
      this.offsetY = this.holderBounds.height/2 - (this.photoBounds.height * this.zoom)/2;

      this.redraw();
      return;
    }

    this.offsetX = cx - (cx - this.offsetX) * intensity;
    this.offsetY = cy - (cy - this.offsetY) * intensity;
    this.move(0, 0);
  };

  move = (dx, dy) => {
    const over = 0.5;
    const pw = this.photoBounds.width * this.zoom;
    const ph = this.photoBounds.height * this.zoom;

    const minX = (this.holderBounds.width < pw)
      ? -(pw + (this.holderBounds.width * over) - this.holderBounds.width)
      : -(pw * over);

    const maxX = (this.holderBounds.width < pw)
      ? (this.holderBounds.width * over)
      : (this.holderBounds.width - (pw - pw*over));

    const minY = (this.holderBounds.height < ph)
      ? -(ph + (this.holderBounds.height * over) - this.holderBounds.height)
      : -(ph * over);

    const maxY = (this.holderBounds.height < ph)
      ? (this.holderBounds.height * over)
      : (this.holderBounds.height - (ph - ph*over));

    this.offsetX += dx;
    this.offsetX = Math.min(this.offsetX, maxX);
    this.offsetX = Math.max(this.offsetX, minX);

    this.offsetY += dy;
    this.offsetY = Math.min(this.offsetY, maxY);
    this.offsetY = Math.max(this.offsetY, minY);

    this.redraw();
  };

  getRealPosition = (x, y) => {
    x -= this.offsetX;
    y -= this.offsetY;

    x *= this.maskCanvas.width / this.photoBounds.width;
    y *= this.maskCanvas.height / this.photoBounds.height;

    x /= this.zoom;
    y /= this.zoom;

    return {x, y};
  };

  paintMask = (x, y, x2, y2, tool, toolSize, isPoint = false) => {
    const point1 = this.getRealPosition(x, y);
    const point2 = this.getRealPosition(x2, y2);
    const width = this.photoImage.width * (toolSize / (this.photoBounds.width * this.zoom));

    this.maskCanvasCtx.save();
    this.maskCanvasCtx.strokeStyle = maskColor;
    this.maskCanvasCtx.fillStyle = maskColor;
    this.maskCanvasCtx.lineCap = "round";
    this.maskCanvasCtx.lineWidth = width;

    if (tool === tools.pen) {
      this.maskCanvasCtx.globalCompositeOperation = "source-over";
    } else {
      this.maskCanvasCtx.globalCompositeOperation = "destination-out";
    }

    this.maskCanvasCtx.beginPath();

    if (isPoint) {
      this.maskCanvasCtx.arc(point2.x, point2.y, width/2, 0 * Math.PI, 2 * Math.PI);
      this.maskCanvasCtx.fill();
    } else {
      this.maskCanvasCtx.moveTo(point1.x, point1.y);
      this.maskCanvasCtx.lineTo(point2.x, point2.y);
      this.maskCanvasCtx.stroke();
    }

    this.maskCanvasCtx.restore();

    this.redrawUpperCanvas();

    if (this.state.viewMode === viewModes.preview) {
      this.redrawLowerCanvas();
    }
  }

  redraw = () => {
    this.redrawUpperCanvas();
    this.redrawLowerCanvas();
  }

  redrawUpperCanvas = () => {
    window.cancelAnimationFrame(this.upperCanvasRequestFrame);
    this.upperCanvasRequestFrame = window.requestAnimationFrame(this._redrawUpperCanvas);
  };

  _redrawUpperCanvas = () => {
    this.upperCanvasCtx.save();

    this.upperCanvasCtx.clearRect(0, 0, this.upperCanvas.width, this.upperCanvas.height);
    this.upperCanvasCtx.translate(this.offsetX, this.offsetY);
    this.upperCanvasCtx.scale(this.zoom, this.zoom);
    this.upperCanvasCtx.drawImage(
      this.maskCanvas,
      0, 0,
      this.maskCanvas.width, this.maskCanvas.height,
      0, 0,
      this.maskBounds.width, this.maskBounds.height
    );

    this.upperCanvasCtx.restore();
  };

  redrawLowerCanvas = () => {
    window.cancelAnimationFrame(this.lowerCanvasRequestFrame);
    this.lowerCanvasRequestFrame = window.requestAnimationFrame(this._redrawLowerCanvas);
  };

  _redrawLowerCanvas = () => {
    this.lowerCanvasCtx.save();

    this.lowerCanvasCtx.clearRect(0, 0, this.lowerCanvas.width, this.lowerCanvas.height);
    this.lowerCanvasCtx.translate(this.offsetX, this.offsetY);
    this.lowerCanvasCtx.scale(this.zoom, this.zoom);
    this.lowerCanvasCtx.drawImage(
      this.photoCanvas,
      0, 0,
      this.photoCanvas.width, this.photoCanvas.height,
      0, 0,
      this.photoBounds.width, this.photoBounds.height
    );

    if (this.state.viewMode === viewModes.preview) {
      this.lowerCanvasCtx.globalCompositeOperation = "destination-in";
      this.lowerCanvasCtx.drawImage(
        this.maskCanvas,
        0, 0,
        this.photoCanvas.width, this.photoCanvas.height,
        0, 0,
        this.photoBounds.width, this.photoBounds.height
      );
    }

    this.lowerCanvasCtx.restore();
  };

  storeMaskState = () => {
    const history = this.state.history.filter((item, index) => index <= this.state.historyIndex);

    history.push(this.maskCanvasCtx.getImageData(0, 0, this.maskCanvas.width, this.maskCanvas.height));

    this.setState({
      history: history.length > maxHistoryEntries
        ? history.slice(-maxHistoryEntries)
        : history,
      historyIndex: history.length - 1,
    });
  };

  handleResetLastPaint = () => {
    this.maskCanvasCtx.putImageData(this.state.history[this.state.historyIndex], 0, 0);
    this.redrawUpperCanvas();

    if (this.state.viewMode === viewModes.preview) {
      this.redrawLowerCanvas();
    }
  };

  handleUndo = (e) => {
    if (this.state.historyIndex < 1) {
      return;
    }

    const prevEntryIndex = this.state.historyIndex - 1;

    this.maskCanvasCtx.putImageData(this.state.history[prevEntryIndex], 0, 0);
    this.redrawUpperCanvas();

    if (this.state.viewMode === viewModes.preview) {
      this.redrawLowerCanvas();
    }

    this.setState({historyIndex: prevEntryIndex});
  };

  handleRedo = (e) => {
    if (this.state.historyIndex >= this.state.history.length - 1) {
      return;
    }

    const nextEntryIndex = this.state.historyIndex + 1;

    this.maskCanvasCtx.putImageData(this.state.history[nextEntryIndex], 0, 0);
    this.redrawUpperCanvas();

    if (this.state.viewMode === viewModes.preview) {
      this.redrawLowerCanvas();
    }

    this.setState({historyIndex: nextEntryIndex});
  };

  handleSelectPen = () => {
    this.setState({tool: tools.pen});
  };

  handleSelectEraser = () => {
    this.setState({tool: tools.eraser});
  };

  handleToggleViewMode = () => {
    this.setState({
      viewMode: this.state.viewMode === viewModes.over
        ? viewModes.preview
        : viewModes.over,
    }, () => {
      this.redrawLowerCanvas();
    });
  };

  handleToolSizeChange = (e) => {
    this.setState({
      toolSize: parseInt(e.target.value),
      toolSizeIndicatorIsHidden: false,
    });
  };

  handleHideToolSizeIndicator = () => {
    this.setState({
      toolSizeIndicatorIsHidden: true,
    });
  };

  compareImageData = (img1, img2) => {
    if (img1.data.length !== img2.data.length) {
      return false;
    }

    for (let i = 0; i < img1.data.length; ++i) {
      if (img1.data[i] !== img2.data[i]) {
        return false;
      }
    }

    return true;
  }

  handleSaveClick = (e) => {
    this.context.showLoader(!this.state.isAfterResult, this.state.sourceFileUrl);

    const imageData = this.maskCanvasCtx.getImageData(0, 0, this.maskCanvas.width, this.maskCanvas.height);

    if (this.compareImageData(this.initMaskImageData, imageData)) {
      if (this.state.isAfterResult) {
        hitEvent(hits.SKIP_MASK_AFTER_RESULT);
        this.props.history.replace(routes.RESULT);
        return;
      }

      hitEvent(hits.SKIP_MASK_BEFORE_PROCESSING);
    } else {
      hitEvent(hits.MASK_UPDATED);

      this.state.isAfterResult
        ? hitEvent(hits.SAVE_MASK_AFTER_RESULT)
        : hitEvent(hits.SAVE_MASK_BEFORE_PROCESSING);
    }

    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = this.maskCanvas.width;
    offscreenCanvas.height = this.maskCanvas.height;
    const offscreenCanvasCtx = offscreenCanvas.getContext("2d");

    let i = 0;
    while (i < imageData.data.length) {
      i += 3;
      const a = imageData.data[i++];

      if (a === 0) {
        imageData.data[i-2] = 0;
        imageData.data[i-3] = 0;
        imageData.data[i-4] = 0;
      } else {
        imageData.data[i-2] = a;
        imageData.data[i-3] = a;
        imageData.data[i-4] = a;
      }

      imageData.data[i - 1] = 255;
    }

    offscreenCanvasCtx.putImageData(imageData, 0, 0);

    offscreenCanvas.toBlob((blob) => {
      api.createFile(new File([blob], "mask.png")).then((file) => {
        goToProcessing(
          this.props.history,
          this.props.location.state.file,
          file,
          this.state.isAfterResult
        );
      }).catch((error) => {
        this.setState({
          error: error
        }, this.context.hideLoader);
      });
    }, "image/png");
  };

  handleBackPressed = (e) => {
    if (this.state.isAfterResult) {
      this.props.history.replace(routes.RESULT);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  };

  handleFileSelected = (file) => {
    logEvent(userEvents.PHOTO_SELECT, {page: "maskeditor"});

    this.context.showLoader(true, null, () => {
      this.props.history.replace(routes.UPLOAD, {file});
    });
  };

  render() {
    if (!this.context.loader.isHidden) {
      return <React.Fragment />;
    }

    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onFileSelected={this.handleFileSelected}
      />;
    }

    return <main className="editor-page">
      <HomeButton page="maskeditor" onBackPressed={this.handleBackPressed} />

      <div className="container editor-page-container">
        <h2 dangerouslySetInnerHTML={{__html: i18n.t("maskeditor__title")}} />
        {window.clientConfig.isWebDesktop && <p dangerouslySetInnerHTML={{__html: i18n.t("maskeditor__subtitle")}} />}
      </div>
      <div className="screen mask_editor_screen">
        <div
          style={{cursor: "none"}}
          className="canvas_holder"
          ref={(ref) => this.canvasHolder = ref}>
          <canvas
            ref={(ref) => this.lowerCanvas = ref}
            className="lower" />
          <canvas
            ref={(ref) => this.upperCanvas = ref}
            className="upper"
            style={{opacity: (this.state.viewMode === viewModes.preview ? 0 : 0.5)}} />
          <div
            hidden={this.state.cursorIsHidden}
            className={`cursor ${this.state.tool === tools.pen ? "pen" : "eraser"}`}
            style={{
              width: this.state.toolSize,
              height: this.state.toolSize,
              left: this.state.cursorPosition.x,
              top: this.state.cursorPosition.y,
            }} />
          <div
            hidden={this.state.toolSizeIndicatorIsHidden}
            className={`tool-size-indicator ${this.state.tool === tools.pen ? "pen" : "eraser"}`}
            style={{
              width: this.state.toolSize,
              height: this.state.toolSize,
            }}>
              {this.state.tool === tools.pen
              ? <SvgPenIcon />
              : <SvgEraserIcon />
              }
          </div>
        </div>
        <div className="controlbox">
          <span className="control_name">{i18n.t("size")}</span>
          <div className="control_holder">
            <div className="block-score">
              <div className="active-range" style={{width: (this.state.toolSize + "%")}} />
              <input
                type="range"
                min={toolSizeConfig.min}
                max={toolSizeConfig.max}
                step={toolSizeConfig.step}
                value={this.state.toolSize}
                onChange={this.handleToolSizeChange}
                onMouseUp={this.handleHideToolSizeIndicator}
                onTouchEnd={this.handleHideToolSizeIndicator}
              />
            </div>
          </div>
          <span className="control_value">{this.state.toolSize}</span>
        </div>
        <div className="toolbox">
          <div className="buttons-container buttons-container-tools">
            <button
              className={`tool-viewmode ${this.state.viewMode === viewModes.preview ? "active": ""}`}
              onClick={this.handleToggleViewMode}>
              <SvgViewmodeIcon />
            </button>
            <button
              className={`tool-pen ${this.state.tool === tools.pen ? "active" : ""}`}
              onClick={this.handleSelectPen} >
              <SvgPenIcon />
            </button>
            <button
              className={`tool-eraser ${this.state.tool === tools.eraser ? "active" : ""}`}
              onClick={this.handleSelectEraser}>
              <SvgEraserIcon />
            </button>
            <div
              className="tool-save"
              children={i18n.t("btn_save")}
              onClick={this.handleSaveClick} />
          </div>

          <div className="buttons-container buttons-container-arrow">
            <button
              className="tool tool_undo"
              disabled={this.state.historyIndex === 0}
              onClick={this.handleUndo} >
              <SvgLeftIcon />
              <span dangerouslySetInnerHTML={{__html: i18n.t("btn_undo")}} />
            </button>
            <button
              className="tool tool_redo"
              disabled={this.state.historyIndex === this.state.history.length - 1}
              onClick={this.handleRedo} >
              <SvgRightIcon />
              <span dangerouslySetInnerHTML={{__html: i18n.t("btn_redo")}} />
            </button>
          </div>
        </div>
        <div
          className="tool-save"
          children={i18n.t("btn_save")}
          onClick={this.handleSaveClick} />
      </div>
    </main>;
  }
}

function SvgViewmodeIcon() {
  return <svg viewBox="0 0 40 40">
    <path fillRule="evenodd" clipRule="evenodd" d="M39.957 0H.007l2.634.008h2.632l-.546.148A6.308 6.308 0 0 0 .078 5.22c-.031.218-.062-.774-.07-2.43L0 0v40l.008-7.563c.008-6.43.023-7.476.11-7.046.374 1.89 1.632 3.53 3.335 4.359 1.258.617 1.492.656 3.953.695l2.125.04v1.984c0 2.14.07 2.765.43 3.648.734 1.805 2.36 3.227 4.258 3.719l.586.156H7.406L0 40h40l-2.672-.008c-2.406-.008-2.633-.015-2.21-.11 2.194-.476 4.038-2.234 4.648-4.429.086-.312.156-.664.156-.781 0-.125.016-.219.039-.219s.039 1.25.039 2.774V40 29.852c0 3.507-.008 3.546-.148 3.234-.5-1.086-2.079-1.219-2.766-.234-.172.25-.227.453-.29 1.015-.187 1.61-1.163 2.688-2.655 2.93-.664.101-18.141.11-18.774 0A3.262 3.262 0 0 1 13 35.039l-.227-.469V15.04l.211-.445c.399-.844 1.336-1.61 2.188-1.774.531-.11 18.648-.101 19.14 0 1.118.242 1.954.992 2.32 2.086.157.469.165.633.165 5.93v.67c0 4.43-.001 5.062.312 5.42.051.06.112.112.182.172l.076.066c.774.68 1.977.445 2.438-.477l.195-.39V0l-.008 7.555c-.008 6.437-.023 7.484-.11 7.054-.374-1.89-1.632-3.53-3.335-4.359-1.258-.617-1.492-.656-3.945-.695l-2.133-.04V7.532c0-2.14-.07-2.765-.43-3.648-.734-1.805-2.36-3.227-4.258-3.719l-.586-.156h7.407L39.957 0zM3.492 4.984c.446-.937 1.313-1.61 2.29-1.773.234-.04.921-.07 1.523-.078l1.093-.008L5.82 5.703C3.445 8.078 3.242 8.266 3.22 8.07c-.016-.125-.016-.75 0-1.39.023-1.125.039-1.196.273-1.696zm4.61 3.047L3.203 12.93l.016 2.133.023 2.124 7.031-7.03 7.032-7.032H13.008L8.102 8.031zm7.335 1.5 3.157-3.195 3.164-3.203 1.523.023c1.36.024 1.57.04 1.977.196.242.093.445.195.445.226s-1.328 1.383-2.945 3l-2.953 2.953h-4.368zm10.344-1.445L24.336 9.53H27.265V8.086c0-.797-.007-1.445-.023-1.445-.008 0-.664.648-1.46 1.445zM6.351 18.609l-3.148 3.149v1.43c0 1.234.024 1.492.164 1.914.086.265.188.507.219.539.023.03 1.375-1.266 3-2.891l2.945-2.945-.015-2.172-.024-2.164-3.14 3.14zm.25 8.657 1.446-1.446c.797-.797 1.453-1.445 1.46-1.445.016 0 .024.648.024 1.445v1.446H6.601z" />
  </svg>;
}

function SvgPenIcon() {
  return <svg viewBox="0 0 40 40">
    <path fillRule="evenodd" clipRule="evenodd" d="M37.257 4.055a2.731 2.731 0 0 0-1.304-1.297 2.582 2.582 0 0 0-1.914-.102c-1.75.625-4.97 2.313-7.242 3.805-4.727 3.11-8.297 6.648-11.383 11.273-.782 1.18-1.625 2.57-1.579 2.61.016.015.305.14.641.273a8.745 8.745 0 0 1 4.977 5.149l.18.484.359-.203c7.031-4.086 12.312-9.742 16.015-17.14 1.243-2.5 1.446-3.024 1.454-3.79 0-.515-.04-.703-.204-1.062zM16.484 25.367c-.875-1.351-2.117-2.242-3.75-2.687-.531-.14-2.016-.196-2.54-.094-1.726.336-3.163 1.273-4.1 2.656-.727 1.07-1.008 2.008-1.094 3.664-.07 1.227-.133 1.516-.485 2.219-.226.453-.914 1.203-1.5 1.64-.633.477-.672 1.141-.101 1.938a6.77 6.77 0 0 0 4.375 2.719c.953.156 2.875.062 3.828-.188.86-.218 1.984-.726 2.703-1.218.648-.43 1.484-1.235 1.969-1.867.546-.72 1.203-2.087 1.445-3.016.188-.688.21-.938.219-2.07.008-.985-.024-1.399-.125-1.758-.227-.828-.453-1.344-.844-1.938z" />
  </svg>;
}

function SvgEraserIcon() {
  return <svg viewBox="0 0 40 40">
    <path fillRule="evenodd" clipRule="evenodd" d="M27.531 4.64a5.319 5.319 0 0 0-1.015-.546c-.438-.172-.696-.211-1.36-.235-.453-.015-.89-.007-.976.016-.461.11-1.14.398-1.524.633-.265.172-2.023 1.867-4.672 4.5l-4.234 4.234 7.086 7.086c3.906 3.906 7.133 7.094 7.172 7.094.047 0 2.023-1.945 4.39-4.32l.002-.002c4.288-4.295 4.303-4.311 4.623-4.975.61-1.281.61-2.469 0-3.75-.32-.664-.336-.68-4.67-5.022l-.001-.001c-2.391-2.399-4.563-4.524-4.82-4.711zm-4.695 28.032c1.836-1.836 3.336-3.367 3.336-3.414 0-.047-3.188-3.266-7.094-7.172L11.992 15l-4.351 4.352-.287.288c-4.042 4.064-4.064 4.086-4.377 4.735-.618 1.297-.618 2.46 0 3.75.32.656.367.71 3.89 4.25 2.406 2.414 3.664 3.625 3.875 3.727.29.132.578.148 4.242.148 3.352 0 3.97-.016 4.22-.117.194-.086 1.343-1.18 3.632-3.461z" />
  </svg>;
}

function SvgRightIcon() {
  return <svg viewBox="0 0 40 40">
    <path fillRule="evenodd" clipRule="evenodd" d="M40 0v23.555l-.164.351c-.117.266-.992 1.196-3.57 3.774-1.946 1.945-3.547 3.5-3.727 3.593-.46.243-1.156.227-1.61-.046-.437-.258-7.038-6.875-7.226-7.243-.094-.172-.14-.445-.14-.742-.008-.906.617-1.601 1.507-1.664.828-.062.875-.031 3.078 2.156l2.008 2v-.382c0-.696-.156-2.086-.32-2.844a13.354 13.354 0 0 0-3.367-6.375c-1.985-2.078-4.258-3.344-7.125-3.953-.969-.211-3.602-.274-4.617-.102-3.758.61-6.993 2.688-9.102 5.836-.516.773-1.164 2.078-1.469 2.953-.586 1.672-.867 3.781-.718 5.383l.055.6c.03.328.058.64.077.814.055.656-.062 1.016-.476 1.43-.531.531-1.203.648-1.89.328a1.602 1.602 0 0 1-.876-.985c-.078-.25-.21-1.101-.273-1.796-.016-.149-.04 2.797-.04 6.539L0 40V0l.008 12.125c.008 7.523.039 11.883.078 11.477.336-3.22 1.672-6.407 3.75-8.954.547-.671 1.805-1.914 2.492-2.468 2.242-1.797 5.164-3.055 8.094-3.477 1.234-.18 3.5-.18 4.68 0 5.687.875 10.343 4.29 12.78 9.383 1 2.086 1.634 4.766 1.634 6.89v.602l1.859-1.844c1.11-1.101 1.984-1.906 2.18-2.007.414-.204 1.039-.211 1.476-.016.36.164.782.633.883.984.047.14.078-4.484.078-11.234L40 0z" />
  </svg>;
}

function SvgLeftIcon() {
  return <svg viewBox="0 0 40 40">
    <path fillRule="evenodd" clipRule="evenodd" d="M40 0v40l-.016-6.82c0-3.742-.023-6.688-.039-6.54-.062.696-.195 1.547-.273 1.797a1.602 1.602 0 0 1-.875.985c-.688.32-1.36.203-1.89-.328-.423-.422-.532-.766-.47-1.469.188-2.055.196-2.32.118-3.29-.47-6.038-4.72-10.874-10.68-12.155-1.023-.22-3.54-.274-4.625-.102-5.523.867-9.906 4.992-11.086 10.43-.164.758-.32 2.148-.32 2.844v.382l2.015-2c2.196-2.187 2.243-2.218 3.07-2.156.891.063 1.516.758 1.508 1.664 0 .297-.054.57-.14.742-.188.36-6.953 7.125-7.313 7.313-.367.187-1.117.187-1.484 0-.148-.078-1.813-1.68-3.703-3.555C1.109 25.07.336 24.25.187 23.945L0 23.555V0l.008 11.46c0 6.75.031 11.376.078 11.235.102-.351.523-.82.883-.984.437-.195 1.062-.188 1.476.016.196.101 1.07.906 2.188 2.007l1.851 1.844v-.656c.016-2.914 1.094-6.367 2.805-8.914a16.8 16.8 0 0 1 17.258-7.133C31.82 9.93 36.203 13.43 38.5 18.422c.688 1.484 1.234 3.515 1.414 5.18.04.406.07-3.954.078-11.477L40 0z" />
  </svg>;
}

MaskEditorPage.contextType = AppContext;