import {hitEvent, hits} from "./log";
import {webviewPreloadAd, webviewShowAd} from "./webview";
import clientStorage from "./client-storage";

const cacheKey = "native_ads_hits:fullscreen";

export function showNativeAds() {
  const placeAdHits = clientStorage.getInteger(cacheKey, 0);

  const shouldShowAdsOnAndroid = window.appConfig.nativeAds.isEnabled
    && !window.clientConfig.isPro
  ;

  const shouldShowAdsOnIOS = window.appConfig.nativeAds.isEnabled
    && !window.clientConfig.isPro
  ;

  const shouldShowAds = window.clientConfig.isWebviewIOS
    ? shouldShowAdsOnIOS
    : shouldShowAdsOnAndroid;

  if (shouldShowAds) {
    if (window.clientConfig.isWebview) {
      hitEvent(hits.NATIVE_ADS_SHOW_REQUEST);
      webviewShowAd(adShown, adClosed);
    } else {
      console.log("start fullscreen ads");
    }
  }

  clientStorage.setInteger(cacheKey, placeAdHits + 1);
}

export function resetNativeAdsCounter() {
  clientStorage.setInteger(cacheKey, 0);
}

function adShown(params) {
  const isShown = parseInt(params) === 1;
  if (isShown) {
    hitEvent(hits.NATIVE_ADS_SHOWN);
  }
}

function adClosed() {
  hitEvent(hits.NATIVE_ADS_PRELOAD);
  webviewPreloadAd();
}
