import genderHandler from "./gender.handler";
import comboHandler from "./combo.handler";
import groupedCollageHandler from "./grouped-collage.handler";
import ingroupCollageHandler from "./ingroup-collage.handler";

export const handlersNames = {
  GENDER: "gender",
  COMBO: "combo",
  GROUPED_COLLAGE: "grouped_collage",
  INGROUP_COLLAGE: "ingroup_collage",
};

const handlersMap = {
  [handlersNames.GENDER]: genderHandler,
  [handlersNames.COMBO]: comboHandler,
  [handlersNames.GROUPED_COLLAGE]: groupedCollageHandler,
  [handlersNames.INGROUP_COLLAGE]: ingroupCollageHandler,
};

export function getHandlerByName(name) {
  return handlersMap[name] || null;
}