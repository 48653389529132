import React from "react";
import {webviewOpenBrowser} from "../utils/webview";
import i18n from "../i18n";

const PHOTO_LAB_LINK = "https://y3nay.app.goo.gl/creepyartist";

export class FooterLinksView extends React.Component {

  handlePhotoLabClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(PHOTO_LAB_LINK);
    }
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    return <div className="footer-links">
      <div className="container">
        {/* <a href={PHOTO_LAB_LINK}
          target="_blank"
          hidden={window.clientConfig.isWebview}
          onClick={this.handlePhotoLabClick}
          rel="noopener noreferrer">by Photo Lab</a> */}

        <a href="mailto:newprofilepic@pho.to"
          hidden={window.clientConfig.isWebview}>{i18n.t("contact_us")}</a>

        <a href="https://pho.to/privacy"
          target="_blank"
          hidden={window.clientConfig.isWebview}
          rel="noopener noreferrer">{i18n.t("privacy_policy")}</a>

        <a href="https://pho.to/terms"
          target="_blank"
          hidden={window.clientConfig.isWebview}
          rel="noopener noreferrer">{i18n.t("terms")}</a>
      </div>
    </div>;
  }
}
