/* eslint-disable no-unused-vars */

import groups from "./groups";
import {
  combo,
  getCreativesInRandomOrder,
  setCreativesGroup,
  whenSplit, whenSplitInRange,
} from "./helpers";
import configGeneric from "./config-generic";
import {pwAssetUrl} from "../../utils/etc";

const step7030 = whenSplit(10) ? 7030 : 1978;

function getProCreatives() {
  const additionalItems = [];
  if (window.clientConfig.isUsaUser) {
    additionalItems.push(
      combo([7044]),
    );
  }

  return setCreativesGroup(groups.PRO, [
    ...additionalItems,
    combo([7088]).setSelected(!window.clientConfig.isNew),
    combo([6890]),
    // 23661260
    combo([
      6756,
      {id: step7030, images: [{useAltBody: true}, {src: "https://storage.ws.pho.to/s2/620a718841f6b786f17d44fca6d43ae7232ab1ed_m.jpeg"}]},
      2856,
    ]).setPreviewUrl(pwAssetUrl("previews/256x256/1/6756_1978_2856.jpg")),
    // 6859
    combo([6888]),
    combo([6756, 6936]),

  ]);
}

export default {
  getGroups: function() {
    return [
      ...configGeneric.getGroups(),
      groups.PRO,
    ];
  },
  getCreatives: function() {
    let listOfAll = [
      ...configGeneric.getCreatives(),
      ...getProCreatives(),
    ];

    if (window.clientConfig.features.tabsWithRandomOrder) {
      listOfAll = [
        ...configGeneric.getCreatives(),
        ...getCreativesInRandomOrder(getProCreatives()),
      ];
    }

    return listOfAll;
  },
};
