import amplitude from "amplitude-js";
import axios from "axios";
import {debounce} from "./etc";
import {webviewAnalyticsEvent} from "./webview";

let webviewParamsIsCommited = false;

const userProperties = {
  client_type: window.clientConfig.isWebview ? "webview" : (window.clientConfig.isWebMobile ? "mobile" : "desktop"),
  is_webview: window.clientConfig.isWebview,
  is_mobile: window.clientConfig.isWebMobile,
  is_mobile_desktop_mode: window.clientConfig.isWebMobileDesktopMode,
  platform_browser_name: window.clientConfig.platform.name,
  platform_browser_version: window.clientConfig.platform.version,
  platform_os: window.clientConfig.platform.os,
  screen_w: window.screen.width,
  screen_h: window.screen.height,
  viewport_w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  viewport_h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  locale: window.clientConfig.locale,
  is_pro: window.clientConfig.isPro,
  split_group: window.clientConfig.splitGroupId,
};

if (window.clientConfig.isWebview) {
  const osName = window.clientConfig.isWebviewAndroid
    ? "Android"
    : "iOS";

  userProperties.os_version = `${osName} ${window.clientConfig.webviewParams.os_version}`;
  userProperties.native_app_build = `${osName} ${window.clientConfig.webviewParams.version_code}`;
}

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProperties);
}

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({...userProperties});
}

// --

export const userEvents = {
  PAGE: "page",

  // юзер нажал на "попробовать другое фото"
  // параметры: page (страница: create, result, error)
  HOME_BUTTON_CLICK: "home_button_click",

  // юзер выбрал фото
  // параметры: page (страница: index, error, result)
  PHOTO_SELECT: "photo_select",

  // юзер успешно загрузил фото
  PHOTO_UPLOADED: "photo_uploaded",

  // юзер неуспешно загрузил фото
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  TAB_SELECT: "tab_select",
  TAB_REFRESH: "tab_refresh",

  PROCESSING_PROCESSED: "processing_processed",
  PROCESSING_FAILED: "processing_failed",

  CREATIVE_STARTED: "creative_started",
  CREATIVE_PROCESSED: "creative_processed",
  CREATIVE_FAILED: "creative_failed",

  CREATIVE_VIEW: "creative_view",

  DOWNLOAD: "download",

  GET_APP_CLICK: "get_app_click",

  RATEAPP_DIALOG_SHOW: "rateapp_dialog_show",
  RATEAPP_DIALOG_RATE: "rateapp_dialog_rate",
  RATEAPP_DIALOG_SKIP: "rateapp_dialog_skip",

  COMPONENT_ERROR: "component_error",
  JS_GLOBAL_ERROR: "js_global_error",

  CHANGE_MASK_CLICK: "change_mask_click",
  SHOW_MORE_CLICK: "show_more_click",

  FRONTEND_WATERMARK_FAILED: "frontend_watermark_failed",

  RESUBSCRIBE_BANNER_SHOW: "resubscribe_banner_show",
  RESUBSCRIBE_CLICK: "resubscribe_click",

  FULL_SIZE_RESULTS_CLICK: "full_size_results_click",
  FULL_SIZE_PROMOTION_SHOW: "full_size_promotion_show",
  FULL_SIZE_PROMOTION_SKIP_CLICK: "full_size_promotion_skip_click",
  FULL_SIZE_PROMOTION_DEEPLINK_CLICK: "full_size_promotion_deeplink_click",
};

export const hits = {
  TAB_OPENED: 0,
  PHOTO_SELECT: 8879,
  PHOTO_SELECT1: 9007,
  PHOTO_SELECT2: 9008,
  PHOTO_SELECT_OLD: 9009,
  PHOTO_UPLOADED: 8880,
  PAGE_MASK_BEFORE_PROCESSING: 8881,
  SAVE_MASK_BEFORE_PROCESSING: 8883,
  SKIP_MASK_BEFORE_PROCESSING: 8884,
  PROCESSING_PROCESSED: 8885,
  PROCESSING_FAILED: 8886,
  PROCESSING_FAILED_BY_PHOTOLAB: 8976,
  PROCESSING_FAILED_ON_RESULT_PAGE: 8887,
  PROCESSING_TIMEOUT: 8984,

  DOWNLOAD: 8888,
  DOWNLOAD1: 8998,
  DOWNLOAD2: 8999,
  DOWNLOAD_OLD: 9006,

  FIRST_DOWNLOAD_BY_CLIENT: 9010,
  FIRST_DOWNLOAD_BY_PROCESSING: 9011,
  FIRST_DOWNLOAD_BY_TEMPLATE: 9013,

  PAGE_MASK_AFTER_RESULT: 8889,
  SAVE_MASK_AFTER_RESULT: 8890,
  SKIP_MASK_AFTER_RESULT: 8891,
  GET_APP_CLICK: 8935,
  RATEAPP_DIALOG_SHOW: 8936,
  RATEAPP_DIALOG_RATE: 8937,
  RATEAPP_DIALOG_SKIP: 8938,
  RATEAPP_DIALOG_INAPP_RESPONSE_0: 8939,
  RATEAPP_DIALOG_INAPP_RESPONSE_1: 8940,
  RATEAPP_DIALOG_INAPP_RESPONSE_2: 8941,
  RATEAPP_DIALOG_INAPP_RESPONSE_3: 8942,
  INDEX_VISIT_WEB: 8977,
  INDEX_VISIT_WEB_DESKTOP: 9053,
  INDEX_VISIT_WEB_MOBILE: 9054,
  INDEX_VISIT_WEBVIEW: 8978,
  NATIVE_ADS_PRELOAD: 8981,
  NATIVE_ADS_SHOW_REQUEST: 8982,
  NATIVE_ADS_SHOWN: 8983,
  START_ALTERNATIVE_CREATIVES: 8979,
  START_PENDING_CREATIVE: 8980,
  FIRST_CLICK_TAB: 8985,
  FIRST_CLICK_TAB_MORE: 8987,
  FIRST_CLICK_REFRESH_CREATIVE: 8986,

  FRONTEND_WATERMARK_STARTED: 8988,
  FRONTEND_WATERMARK_PROCESSED: 8989,
  FRONTEND_WATERMARK_FAILED: 8990,
  FRONTEND_WATERMARK_FAILED_TIMEOUT: 9042,
  COMPONENT_ERROR: 8993,
  START_PENDING_PROCESSING: 8991,
  START_AUTO_PROCESSING: 8992,
  DOWNLOAD__AUTO_PROCESSING: 8994,
  DOWNLOAD__PENDING_PROCESSING: 8995,
  NATIVE_ADS_SHOW_REQUEST__AUTO_PROCESSING: 8996,
  NATIVE_ADS_SHOW_REQUEST__PENDING_PROCESSING: 8997,
  ANALYTICS_ERROR: 9027,

  FORCE_REDIRECT_PROCESSING: 9039,

  MASK_UPDATED: 9040,

  REQUEST_PHOTOLAB_SIGN_FAIL: 9049,
  REQUEST_PHOTOLAB_SIGN_ALT_FAIL: 9050,

  TAB_CLICK_1: 9000,
  TAB_CLICK_2: 9001,
  TAB_CLICK_OLD: 9004,
  REFRESH_TAB_CLICK_1: 9002,
  REFRESH_TAB_CLICK_2: 9003,
  REFRESH_TAB_CLICK_OLD: 9005,

  DOWNLOAD_WITH_WATERMARK: 9056,
  DOWNLOAD_WITHOUT_WATERMARK: 9057,

  WATERMARK_POPUP_REMOVE_CLICK: 9058,
  WATERMARK_POPUP_KEEP_CLICK: 9059,
};

const hitsCache = {};
export function hitEvent(id, count = 1, ignoreUserGroup = false, delay = 1000) {
  const config = window.appConfig.hits;

  if (id === 0) {
    console.warn("Zero-valued hit triggered");
    return;
  }

  if (window.appConfig.isDebug) {
    const hitName = Object.keys(hits).find((key) => hits[key] === id) || "(unknown)";
    console.info("hitEvent", JSON.stringify({hitName, id, count, ignoreUserGroup}));
  }

  if (!config.isEnabled) {
    return;
  }

  if (ignoreUserGroup || config.allowedUserGroups.includes(window.clientConfig.splitGroupId)) {
    hitsCache[id] = (hitsCache[id] || 0) + count;
    debounce("hitEvent." + id, delay, () => {
      const c = hitsCache[id] || 1;
      hitsCache[id] = 0;

      window.axios.post(`${config.endpoint}?id=${id}&c=${c}&r=${Date.now()}`)
        .then(() => {/* dummy */})
        .catch(console.error);
    });
  }
}

export function logEvent(eventId, eventParams, cb) {
  eventParams = eventParams || {};
  eventParams.build_version = window.appConfig.build.version;

  if (window.appConfig.isDebug) {
    console.debug("logEvent", eventId, JSON.stringify(eventParams));
  }

  _logEventAmplitude(eventId, eventParams, cb);

  if (window.appConfig.analytics.isEnabled) {
    const isAllow = !window.appConfig.analytics.overloadModeIsEnabled
      ||
      (window.appConfig.analytics.overloadModeIsEnabled
        && window.appConfig.analytics.overloadModeEvents.includes(eventId))
    ;

    if (isAllow) {
      _logEvent(eventId, eventParams).then(() => {
        cb && cb();
      });
    }
  }
}

function _logEventAmplitude(eventId, eventParams, cb) {
  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }
}

function _logEvent(eventId, eventParams) {
  let userParams = undefined;
  if (userPropertiesCommitWaited.length > 0) {
    userParams = {};
    userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
    userPropertiesCommitWaited.length = 0;
  }

  let webviewParams = undefined;
  if (window.clientConfig.isWebview && !webviewParamsIsCommited) {
    webviewParamsIsCommited = true;
    webviewParams = window.clientConfig.webviewParams;
  }

  return axios.post(window.appConfig.analytics.endpoint, {
    client_token: window.clientConfig.token,
    client_params: userParams,
    client_webview_params: webviewParams,
    project_name: window.appConfig.project.name,
    event_name: eventId,
    event_params: eventParams,
    locale: window.clientConfig.locale,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    hitEvent(hits.ANALYTICS_ERROR, 1, true, 1);
    console.error(err);
  });
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.debug("setUserProperty", key, value);
  }

  const currentValue = userProperties[key];
  if (currentValue !== value) {
    userProperties[key] = value;
    userPropertiesCommitWaited.push(key);
  }

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().identify(new amplitude.Identify().set(key, value));
  }
}

export function logProcessingsTimings(time) {
  if (!window.appConfig.processingTimings.isEnabled) {
    return;
  }

  return axios.post(window.appConfig.processingTimings.endpoint, {
    project_name: window.appConfig.project.name,
    time,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    console.error(err);
  });
}

// --

export const userNativeEvents = {
  templateSelected: "template_selected",
  templateApplied: "template_applied",
  resultShown: "result_shown",
  saveAndShare: "save_and_share",
  buttonTapped: "button_tapped",
  screenShown: "screen_shown",
  maskEdited: "mask_edited",
};

export function logEventNative(eventName, values = []) {
  if (window.appConfig.isDebug) {
    console.debug("logEventNative", eventName, JSON.stringify(values));
  }

  if (!Array.isArray(values)) {
    console.warn(`logEventNative(${eventName}, values): values is not an array`);
    return;
  }

  if (window.clientConfig.isWebview) {
    if (window.appConfig.nativeAnalytics.isEnabled) {
      webviewAnalyticsEvent(eventName, ...values);
    }
  }
}