import Modal from "./Modal";
import React from "react";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";
import clientStorage from "../utils/client-storage";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import * as webviewUtils from "../utils/webview";

export default class RateAppModal extends Modal {

  constructor(props) {
    super(props);
    this.className = ""; // todo
  }

  handleRateAppSkipButtonClick = () => {
    clientStorage.setRateAppRequestIsSkip(true);

    hitEvent(hits.RATEAPP_DIALOG_SKIP);
    logEvent(userEvents.RATEAPP_DIALOG_SKIP);

    this.dismiss();
  };

  handleRateAppRateButtonClick = () => {
    clientStorage.setRateAppRequestIsRate(true);

    hitEvent(hits.RATEAPP_DIALOG_RATE);
    logEvent(userEvents.RATEAPP_DIALOG_RATE);

    this.dismiss();
    //if (window.clientConfig.isWebviewAndroid) {
      webviewUtils.webviewRateApp((res) => {
        switch (parseInt(res)) {
          case 0: hitEvent(hits.RATEAPP_DIALOG_INAPP_RESPONSE_0); return;
          case 1: hitEvent(hits.RATEAPP_DIALOG_INAPP_RESPONSE_1); return;
          case 2: hitEvent(hits.RATEAPP_DIALOG_INAPP_RESPONSE_2); return;
          case 3: hitEvent(hits.RATEAPP_DIALOG_INAPP_RESPONSE_3); return;
          default:
            return;
        }
      });
    // } else {
    //   webviewUtils.webviewOpenBrowser(APPSTORE_LINK);
    // }
  };

  renderModal() {
    return <div className="modal-container">
      <div className="modal modal_rate">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("rateapp_dialog__message")}}></p>
        <button
          className="rate-button"
          children={i18n.t("rateapp_dialog__rate_button")}
          onClick={this.handleRateAppRateButtonClick} />
        <button
          className="skip-button"
          children={i18n.t("rateapp_dialog__skip_button")}
          onClick={this.handleRateAppSkipButtonClick} />
      </div>
    </div>
  }
}

RateAppModal.contextType = AppContext;
