import Creative from "../photolab/Creative";
import Processing from "../photolab/Processing";
import {pwAssetUrl} from "./etc";
import processingManager from "../photolab/ProcessingManager";

export function resolveCreativeImageFile(creative, sharing) {
  if (creative.getExtra(Creative.EXTRA_COLLAGE_SHOW_COMPLEX)) {
    return (sharing && creative.getFile("share_complex"))
      || creative.getFile("complex_watermark") || creative.getFile("complex");
  }

  // if (sharing) {
  //   return creative.getFile("share_raw");
  // }

  if (processingManager.processing
    && processingManager.processing.getExtra(Processing.EXTRA_PRO_WATERMARK_SHOULD_BE_REMOVED, false)
  ) {
    return creative.getFile("raw");
  }

  return creative.getFile("raw_watermark") || creative.getFile("raw");
}

export function resolveCreativePreviewFile(creative) {
  if (creative && creative.isProcessed) {
    return resolveCreativeImageFile(creative);
  }

  if (creative && creative.hasExtra(Creative.EXTRA_PREVIEW_URL)) {
    return creative.getExtra(Creative.EXTRA_PREVIEW_URL);
  }

  if (creative && creative.hasExtra(Creative.EXTRA_COMBO_STEPS)) {
    let steps = creative.getExtra(Creative.EXTRA_COMBO_STEPS).map((s) => s.id).join("_");

    if (creative.hasExtra(Creative.EXTRA_PREVIEW_URL_FIXED)) {
      steps += "_fixed";
    }

    // 1 - это https://assets.photo-cdn.net/previews/source/1.jpg
    return pwAssetUrl(`previews/256x256/1/${steps}.jpg`);
  }

  return null;
}

export function creativeIsNew(creative) {
  if (creative.hasExtra(Creative.EXTRA_VISIBLE_AFTER_AT)) {
    const offsetMinutes = creative.getExtra(Creative.EXTRA_STATUS_NEW_DURATION)
      || window.appConfig.processings.creativeStatusNewDuration;

    const at = creative.getExtra(Creative.EXTRA_VISIBLE_AFTER_AT) + (offsetMinutes * 60_000);

    return Date.now() <= new Date(at);
  }

  return creative.getExtra(Creative.EXTRA_FORCE_IS_NEW, false) === true;
}

export function transformToDownloadUrl(imageUrl) {
  const appUrl = new URL(window.appConfig.paths.proxy);
  const url = new URL(imageUrl);
  const hostname = url.hostname.toLowerCase();

  if (hostname === "temp-images.ws.pho.to") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/temp-images.ws.pho.to" + url.pathname;
  }

  if (hostname === "worker-images.ws.pho.to") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/worker-images.ws.pho.to" + url.pathname;
  }

  if (hostname.match(/worker-images-[2-9].ws.pho.to/)) {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/" + hostname + url.pathname;
  }

  if (hostname === "webview-app.ws.pho.to") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download" + url.pathname;
  }

  if (hostname === "pw.test.photo-cdn.net") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/pw.test.photo-cdn.net" + url.pathname;
  }

  if (hostname === "client-upload-proxy") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/uploads/npp/creatives" + url.pathname;
  }

  return url.toString();
}

export function creativeName(creative) {
  return creative.getExtra(Creative.EXTRA_NAME, creative.templateId);
}
