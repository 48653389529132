module.exports = {
  "strings": {
    "try_another_photo": "更改照片",

    "time_next_try": "⏰ 稍后再试: {{time}}",

    "safe_and_cool": "帮助我们打击虚假新闻！",

    "landing__title_1": "令人惊叹的头像制作工具。",
    "landing__title_2": "每周新功能。完全由人工智能驱动。",
    "landing__subtitle": "周围的世界在不断变化，而你的在线形象也应如此！",
    "landing__get_the_app_button": "获取应用",

    "choose_photo": "选择照片",

    "choose_hide_text": "隐藏说明文字",
    "choose_show_text": "显示说明文字",

    "modal__title": "选得好！",
    "modal__subtitle": "现在是更新你的个人资料照片，让你的粉丝嫉妒的时候了 ;)",

    "btn_creative_refresh": "还有更多",

    "main_info_title": "10多万人喜欢它 / 10多万个赞",
    "main_info_text": "通过反映你当前情绪的神采奕奕的头像，让你的朋友和粉丝啧啧称奇。",

    "rules_list_title": "规则很简单：",
    "rules_list_item1": "以你目前的心理状态选择自己最喜欢的个人资料照片",
    "rules_list_item2": "一周后回来查看新效果、新选项和新照片。",

    "processing_tip": "提示 {{item}}/{{total}}",
    "processing_text_1": "为什么只用一张个人资料照片？改变一下吧！🙈",
    "processing_text_2": "打破常规，随时更新照片！😎",
    "processing_text_3": "选择反映您当前心情的艺术作品！🤩",
    "processing_text_4": "每次都用漂亮的全新个人资料给您的朋友带来惊喜 👍",
    "processing_text_5": "隔几天就登录找找灵感 😉",

    "processing2_0": "打破常规！按照您的方式去做：",
    "processing2_1": "每隔几天就更新一次您的个人资料照片 🔄",
    "processing2_2": "您这样做时，就会看到对您个人资料关注度的增加 😎",
    "processing2_3": "返回查看全新的效果和设计 🤩",
    "processing2_4": "重复 🚀",

    "resubscribe_text1": "&nbsp;",
    "resubscribe_text2": "糟糕，我们遇到情况了…",
    "resubscribe_text3": "您已停止订阅，现在所有Premium功能都被锁定了。<br />重新订阅以修复问题。",

    "maskeditor__title": "细化选区",
    "maskeditor__subtitle": "滚动缩放，用鼠标中键拖曳",

    "btn_undo": "撤销",
    "btn_redo": "重做",
    "btn_save": "保存",
    "size": "尺寸",
    "btn_get": "获取免费应用",

    "result__creative_download": "下载",
    "result__creative_download_webview": "保存与分享",
    "result__creative_retry": "重试",
    "result__creative_failed_message": "哎哟，这个样式出了点问题。😔 选择任何其它样式，或者重试。",
    "result__choose_title": "无法选择？",
    "result__choose_text": "发布设有不同选项的拼贴画，请你的粉丝帮忙",

    "rateapp_dialog__title": "爱上<span>#NewProfilePic</span>了吗？",
    "rateapp_dialog__message": "请为应用评分，帮助其他用户也发现并爱上它！",
    "rateapp_dialog__rate_button": "评分！",
    "rateapp_dialog__skip_button": "跳过",

    "ad_tab_title": "在我们的<span>Photo Lab</span>应用中找到更多精彩内容",

    "error__error": "错误",
    "modal_button": "知道了",

    "remove_logo_modal__title": "保留还是移除……",
    "remove_logo_modal__message": "嘿，你知道那个标识是这个特效设计的一部分吗？如果你保留它，我们会很开心。",
    "remove_logo_modal__btn_cancel": "保留标识",
    "remove_logo_modal__btn_remove": "坚持移除",

    "toggle_watermark_tooltip__text": "点击水印以去除",
    "toggle_watermark_on": "标识启用/关闭",
    "toggle_watermark_off": "标识启用/关闭",

    "internal_error": "发生了错误……",
    "error__network_message": "请检查您的网络连接。",

    "error_overload": "我们深表歉意，由于应用下载量增加，我们的服务器负载很高。请稍后再回来查看。我们正在努力尽快修复所有问题。",

    "timer__title": "新款式：",
    "timer__expired_text_landing": "选择照片以尝试新样式",
    "timer__expired_text_result": "选择照片以尝试新样式:",

    "timer__days": "天",
    "timer__hours": "小时",
    "timer__minutes": "分钟",
    "timer__seconds": "秒",

    "contact_us": "联系我们",

    "btn_full_size_results_v1": "新功能！ <span>全尺寸结果</span>",
    "btn_full_size_results_v2": "新功能！ 全尺寸结果",
  },
};

