module.exports = {
  "strings": {
    "try_another_photo": "Changer la photo",

    "time_next_try": "⏰ Réessayez dans {{time}}",

    "safe_and_cool": "Aidez-nous à combattre les fake news !",

    "landing__title_1": "Votre incroyable créateur de photos de profil.",
    "landing__title_2": "De nouveaux arrivages chaque semaine. Totalement piloté par l'IA.",
    "landing__subtitle": "Le monde qui t'entoure est en constante évolution et ton image en ligne doit en faire autant !",
    "landing__get_the_app_button": "Obtenez l'application",

    "choose_photo": "Choisissez une photo",

    "choose_hide_text": "Cacher la légende",
    "choose_show_text": "Afficher la légende",

    "modal__title": "Excellent choix !",
    "modal__subtitle": "Il est maintenant temps de mettre à jour votre photo de profil et d'attiser la jalousie de vos amis et de vos followers. ;)",

    "btn_creative_refresh": "{{num}} de plus",

    "main_info_title": "+ de 100 000 personnes ont aimé / + de 100 000 likes",
    "main_info_text": "Étonne tes amis et tes followers avec des avatars au caractère original qui reflètent ton humeur du moment.",

    "rules_list_title": "Les règles sont simples :",
    "rules_list_item1": "Sélectionne la photo de profil que tu préfères dans ton état d'esprit actuel",
    "rules_list_item2": "et reviens une semaine plus tard pour découvrir de nouveaux effets, de nouvelles options et une nouvelle image.",

    "processing_tip": "Conseil {{item}}/{{total}}",
    "processing_text_1": "Pourquoi s’en tenir à une seule photo de profil ? Laissez-la changer avec vous ! 🙈",
    "processing_text_2": "Brisez les règles et mettez votre photo à jour aussi souvent que vous le souhaitez !",
    "processing_text_3": "Choisissez l'artwork qui reflète votre humeur du moment ! 🤩",
    "processing_text_4": "Surprenez vos amis avec un nouveau profil magnifique à chaque fois. 👍",
    "processing_text_5": "Revenez dans quelques jours pour trouver de nouvelles idées. 😉",

    "processing2_0": "Brisez les règles ! Faites-le à votre façon :",
    "processing2_1": "Mettez à jour votre photo de profil une fois en quelques jours. 🔄",
    "processing2_2": "Vous constaterez que l'attention portée à votre profil augmente au fur et à mesure que vous le faites. 😎",
    "processing2_3": "Revenir pour de nouveaux effets et designs 🤩",
    "processing2_4": "Répéter 🚀",

    "resubscribe_text1": "&nbsp;",
    "resubscribe_text2": "Oups, nous avons un problème...",
    "resubscribe_text3": "Vous avez arrêté votre abonnement et maintenant toutes les fonctionnalités Premium sont verrouillées.<br />Réabonnez-vous pour y remédier.",

    "maskeditor__title": "Affiner la sélection",
    "maskeditor__subtitle": "Fais défiler pour zoomer, fais glisser avec le bouton central de la souris.",

    "btn_undo": "Retour",
    "btn_redo": "Rétablir",
    "btn_save": "Enregistrer",
    "size": "Taille",
    "btn_get": "TÉLÉCHARGER L'APPLICATION GRATUITE",

    "result__creative_download": "Télécharger",
    "result__creative_download_webview": "Sauvegarder et partager",
    "result__creative_retry": "Réessayer",
    "result__creative_failed_message": "Oups, quelque chose a mal tourné avec ce modèle. 😔 Choisis un autre style ou recommence.",
    "result__choose_title": "Tu ne peux pas te décider ?",
    "result__choose_text": "Demande à tes followers de t'aider en publiant un collage avec différentes options.",

    "rateapp_dialog__title": "Vous aimez <span>#NewProfilePic</span> ?",
    "rateapp_dialog__message": "Donnez cinq étoiles à l'application et aidez les autres utilisateurs à la découvrir et à l'aimer !",
    "rateapp_dialog__rate_button": "Donner une note !",
    "rateapp_dialog__skip_button": "Passer",

    "ad_tab_title": "Trouvez encore plus de choses intéressantes dans notre application <span>Photo Lab</span>.",

    "error__error": "Erreur",
    "modal_button": "J'ai compris",

    "remove_logo_modal__title": "À garder ou à ne pas garder...",
    "remove_logo_modal__message": "Hé, saviez-vous que ce logo fait partie du design de cet effet ? Ça nous fera plaisir si vous choisissez de le garder.",
    "remove_logo_modal__btn_cancel": "Garder le logo",
    "remove_logo_modal__btn_remove": "Supprimer quand même",

    "toggle_watermark_tooltip__text": "Appuyez sur le logo pour le supprimer",
    "toggle_watermark_on": "Avec logo",
    "toggle_watermark_off": "Sans logo",

    "internal_error": "Une erreur s'est produite...",
    "error__network_message": "Veuillez vérifier votre connexion réseau.",

    "error_overload": "Nous sommes terriblement désolés, mais la charge de nos serveurs est élevée en raison du nombre élevé de téléchargements de l'application. Revenez nous voir un peu plus tard. Nous travaillons dur pour résoudre tous les problèmes rapidement.",

    "timer__title": "Nouveaux styles dans :",
    "timer__expired_text_landing": "Choisissez la photo pour essayer de nouveaux styles",
    "timer__expired_text_result": "Choisissez la photo pour essayer de nouveaux styles:",
    "timer__expired_button": "",

    "timer__days_one": "jour",
    "timer__days_other": "jours",
    "timer__hours_one": "heure",
    "timer__hours_other": "heures",
    "timer__minutes_one": "minute",
    "timer__minutes_other": "minutes",
    "timer__seconds_one": "seconde",
    "timer__seconds_other": "secondes",

    "contact_us": "Contactez-nous",

    "btn_full_size_results_v1": "Nouveau ! <span>Résultats en taille réelle</span>",
    "btn_full_size_results_v2": "Nouveau ! Résultats en taille réelle",
  },
};

