import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import routes from "./routes";
import IndexPage from "./pages/IndexPage";
import UploadPage from "./pages/UploadPage";
import MaskEditorPage from "./pages/MaskEditorPage";
import ProcessingPage from "./pages/ProcessingPage";
import ErrorPage from "./pages/ErrorPage";
import ResultPage from "./pages/result/ResultPage";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {hitEvent, hits, logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import Loading from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";
import {debounce} from "./utils/etc";
import {resetNativeAdsCounter} from "./utils/native-ads";

import ResubscribeScreenView from "./components/ResubscribeScreenView";

window.onerror = function(message, file, line, col, error) {
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error: error.message,
    url: window.location.href,
  });
};

class App extends React.Component {

  state = {
    showTestInstanceAlert: false,
  }

  constructor(props) {
    super(props);

    this.windowHeight = 0;

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.onResume();
      } else {
        this.onPause();
      }
    });
  }

  componentDidMount() {
    this.onResume();

    resetNativeAdsCounter();

    window.webviewEventsListeners.tabSelected.subscribe((v) => {
      v && this.handleWebviewTabSelected();
    }, true);

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewOnPageFinished();

      setTimeout(() => {
        webviewUtils.webviewCheckInstalledApps((apps) => {
          this.context.setInstalledApps(apps);
        });
      }, 200);
    }

    window.clientConfig.platform.os.toLowerCase() === "ios"
      && document.body.classList.add("ios");

    window.clientConfig.isWebview
      && document.body.classList.add("webview");

    document.body.classList.add(`app-lang--${window.clientConfig.lang}`);

    window.clientConfig.features.creativeTabsIsV2
      && document.body.classList.add("ui--creative-tabs-v2");

    document.body.addEventListener("mousewheel", (e) => {
      // отключаем скейлинг страницы по ctrl+scroll
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {passive: false});

    this.updateWindowHeight();
  }

  onResume = () => {
    window.appConfig.isDebug && console.log("app resume");

    this.windowHeightTimer = setInterval(this.updateWindowHeight, 1000/5);

    this.checkIsTestInstance();
  };

  onPause = () => {
    window.appConfig.isDebug && console.log("app pause");

    clearInterval(this.windowHeightTimer);
  };

  checkIsTestInstance = () => {
    if (window.clientConfig.isWeb) {
      return;
    }

    if (!window.appConfig.isTestInstance) {
      return;
    }

    this.setState({showTestInstanceAlert: true}, () => {
      setTimeout(() => this.setState({showTestInstanceAlert: false}), 5000);
    });
  };

  handleWebviewTabSelected = () => {
    debounce("App.handleWebviewTabSelected.preloadAds", 300, () => {
      const shouldPreloadAds = window.clientConfig.isWebview
        && window.appConfig.nativeAds.isEnabled
        && !window.clientConfig.isPro;

      if (shouldPreloadAds) {
        hitEvent(hits.NATIVE_ADS_PRELOAD);
        webviewUtils.webviewPreloadAd();
      }
    });
  };

  updateWindowHeight = () => {
    if (this.windowHeight !== window.innerHeight) {
      this.windowHeight = window.innerHeight;
      document.documentElement.style.setProperty("--window-inner-height", this.windowHeight + "px");
    }
  };

  render() {
    return (
      <div>
        <TestToast
          hidden={!this.state.showTestInstanceAlert}
          onClick={() => this.setState({showTestInstanceAlert: false})}
          children={new URL(window.location.href).hostname} />
        <ErrorBoundary>
          <Loading {...this.context.loader} />
          <BrowserRouter>
            <Switch>
              <Route exact path={routes.INDEX} render={(props) => <IndexPage {...props} />} />
              <Route exact path={routes.UPLOAD} render={(props) => <UploadPage {...props} />} />
              <Route exact path={routes.MASK_EDITOR} render={(props) => <MaskEditorPage {...props} />} />
              <Route exact path={routes.PROCESSING} render={(props) => <ProcessingPage {...props} />} />
              <Route exact path={routes.RESULT} render={(props) => <ResultPage {...props} />} />
              <Route exact path={routes.ERROR} render={(props) => <ErrorPage {...props} />} />
              <Route exact path={routes.RESUBSCRIBE} render={(props) => <ResubscribeScreenView {...props} />} />
            </Switch>
          </BrowserRouter>
          <ModalWrapper />
        </ErrorBoundary>
      </div>
    );
  }
}

App.contextType = AppContext;

const reactRoot = ReactDOM.createRoot(document.getElementById("root"));
reactRoot.render(<AppContextProvider><App /></AppContextProvider>);

function TestToast({hidden, children, onClick}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="app-toast-top" onClick={onClick}>{children}</div>;
}