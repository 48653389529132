import React from "react";

export default class HomeButton extends React.Component {

  handleClick(e) {
    if (this.props.onBackPressed) {
      this.props.onBackPressed();
    }
  }

  render() {
    if (this.props.hidden) {
      return null;
    }

    return <button className="btn-back" onClick={this.handleClick.bind(this)}>
      <svg viewBox="0 0 40 40">
        <path fillRule="evenodd" clipRule="evenodd" d="M40 19.492v1.016l-.203.328c-.11.188-.344.422-.531.531l-.336.196H5.43l2.828 2.828c1.914 1.922 2.867 2.93 2.953 3.125.266.648.047 1.43-.524 1.89-.226.172-.398.235-.718.266-.171.013-.3.033-.426.026-.543-.031-1.057-.565-4.84-4.292C2.36 23.102.343 21.055.227 20.867L0 20.516v-1.032l.227-.351c.117-.188 2.132-2.235 4.476-4.54l.564-.554c3.273-3.226 3.762-3.707 4.276-3.737.09-.005.182.004.289.014l.137.012c.984.086 1.633 1.203 1.242 2.156-.086.196-1.04 1.204-2.953 3.118L5.43 18.438h33.5l.336.195c.187.11.422.344.53.531l.204.328z" fill="#fff"/>
      </svg>
    </button>;
  }
}
