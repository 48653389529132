export default {
  COMMON: "common",
  COLLAGE: "collage",
  CELEBRITY: "celebrity",
  CELEBRITY_2: "celebrity2",
  GLOSS: "gloss",
  BODIES: "bodies",
  PREQUEL: "prequel",
  NOTAI: "notai",
  PRO: "pro",
  RETOUCH: "retouch",
  TAB3: "tab3",
  FULL_SIZE: "full_size",
  ART: "art",
};
