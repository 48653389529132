import React from 'react';
import i18n from "../i18n";
import Loading from "./Loading";
import {assetUrl} from "../utils/etc";
import {webviewCall} from "../utils/webview";
import {logEvent, userEvents} from "../utils/log";
import processingManager from "../photolab/ProcessingManager";

export default class ResubscribeScreenView extends React.Component {

  state = {
    image: null,
  };

  componentDidMount() {
    logEvent(userEvents.RESUBSCRIBE_BANNER_SHOW);
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);
  }

  componentWillUnmount() {
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  handleProcessingChange = (processing) => {
    if (processing && processing.file) {
      this.setState({image: processing.file.url});
    }
  };

  handleResubscribeButtonClick = () => {
    if (!window.clientConfig.subscriptionSku) {
      console.error("No 'subs_id' parameter passed");
      return;
    }

    logEvent(userEvents.RESUBSCRIBE_CLICK);

    webviewCall("inapp", {id: window.clientConfig.subscriptionSku});
  };

  render() {
    return <div className="resubscribe">
      <div>
        <Loading image={this.state.image} />
        <p className='loader-text'
           dangerouslySetInnerHTML={{__html: i18n.t("resubscribe_processing_text")}} />
      </div>
      <img
        className="resubscribe-image"
        src={assetUrl(`assets/images/img_pro.png`)}
        alt="" />
      <div className="resubscribe-content">
        <p className='resubscribe-text1'
           dangerouslySetInnerHTML={{__html: i18n.t("resubscribe_text1")}}/>
        <p className='resubscribe-text2'
           dangerouslySetInnerHTML={{__html: i18n.t("resubscribe_text2")}}/>
        <p className='resubscribe-text3'
           dangerouslySetInnerHTML={{__html: i18n.t("resubscribe_text3")}}/>
        <button
          children={i18n.t("resubscribe_button")}
          onClick={this.handleResubscribeButtonClick} />
      </div>
    </div>
  }
}
