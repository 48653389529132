import React from "react";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import * as api from "../utils/api";
import {fileToJson} from "../utils/webview";
import {logEvent, userEvents} from "../utils/log";
import {goToProcessing} from "../utils/url";
import clientStorage from "../utils/client-storage";
import ErrorView from "../components/ErrorView";
import {promisifyImage} from "../utils/image";
import {ApiResponseError} from "../utils/api";
import i18n from "../i18n";
import processingManager from "../photolab/ProcessingManager";

const IMAGE_MAX_SIDE_SIZE = 1200;

export default class UploadPage extends React.Component {
  state = {
    error: null,
  };

  componentDidMount() {
    logEvent(userEvents.PAGE, {page: "upload"});

    const loadedUrl = new URL(window.location.href);
    const locationState = this.props.location.state || {};

    const fileUrl = loadedUrl.searchParams.has("file_url")
      ? decodeURIComponent(loadedUrl.searchParams.get("file_url"))
      : null;

    const file = fileUrl || locationState.file;

    if (file) {
      this.createFile(file, fileUrl || window.clientConfig.isWebview);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  createFile = (file, isUploaded = false) => {
    if (isUploaded) {
      this.handleFileUploaded(fileToJson(file));
      return;
    }

    // api.createFile(file)
    //   .then(this.handleFileUploaded)
    //   .catch((error) => {
    //     logEvent(userEvents.PHOTO_UPLOAD_FAILED);
    //     this.setState({error}, this.context.hideLoader);
    //   });

    const allowedTypes = [
      "image/jpeg",
      "image/png",
    ];

    if (!allowedTypes.includes(file.type.toLowerCase())) {
      this.setState({
        error: new ApiResponseError({
          error_code: 415,
          error_message: i18n.t("error__api_code_415"),
        }),
      }, this.context.hideLoader);
      return;
    }

    promisifyFileReader(file)
      .then(promisifyImage)
      .then(prepareImage)
      .then((result) => {
        return api.tempImagesUploadFile(result, file.type.replace("image/"));
      })
      .then((fileUrl) => {
        this.handleFileUploaded(fileToJson(fileUrl));
      })
      .catch((error) => {
        this.setState({error}, this.context.hideLoader);
      });
  };

  handleFileSelected = (file) => {
    logEvent(userEvents.PHOTO_SELECT, {page: "upload"});

    this.context.showLoader(true, null, () => {
      this.setState({error: null}, () => {
        this.createFile(file, window.clientConfig.isWebview);
      });
    });
  };

  handleFileUploaded = (file) => {
    // hitEvent(hits.PHOTO_UPLOADED);
    logEvent(userEvents.PHOTO_UPLOADED, {
      file_id: file.id,
      file_url: file.url,
      uploaded_at: Date.now(),
    });

    processingManager.clear();
    clientStorage.setCollageTooltipIsHidden(false);

    // const nextStepIsMask = window.clientConfig.isWebview
    //   ? window.appConfig.maskEditor.isEnabledInWebview
    //   : window.appConfig.maskEditor.isEnabledInWeb;

    const nextStepIsMask = window.clientConfig.isWeb && window.clientConfig.splitGroupId > 10; // false

    this.context.showLoader(true, file.url);

    if (nextStepIsMask) {
      this.props.history.replace({
        pathname: routes.MASK_EDITOR,
        state: {file}
      });
    } else {
      goToProcessing(this.props.history, file);
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onFileSelected={this.handleFileSelected}
      />;
    }

    return <React.Fragment />;
  }
}

UploadPage.contextType = AppContext;

function promisifyFileReader(input) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => resolve(e.target.result);
    fileReader.onerror = (e) => reject(e.target.error);
    fileReader.readAsDataURL(input);
  });
}

function prepareImage(image) {
  const shouldResize = image.width > IMAGE_MAX_SIDE_SIZE || image.height > IMAGE_MAX_SIDE_SIZE;

  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext("2d");

  if (shouldResize) {
    const ratio = (image.width > image.height)
      ? IMAGE_MAX_SIDE_SIZE / image.width
      : IMAGE_MAX_SIDE_SIZE / image.height;

    canvas.width = Math.round(image.width * ratio);
    canvas.height = Math.round(image.height * ratio);

    // eslint-disable-next-line valid-typeof
    if (typeof ctx.imageSmoothingQuality !== undefined) {
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    } else {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      // todo step by step resize to smooth
    }
  } else {
    ctx.drawImage(image, 0, 0);
  }

  return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", 100));
}