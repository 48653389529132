import React from "react";
import i18n from "../../i18n";

export default function CreativeLoaderView(props) {
  return <React.Fragment>
    <div className="loader-roller-wrapper">
      <div className="loader-roller loader-roller1">
        <div className="roller" />
        <div className="roller" />
      </div>
      <div className="loader-roller loader-roller2">
        <div className="roller" />
        <div className="roller" />
        </div>
      <div className="loader-roller loader-roller3">
        <div className="roller" />
        <div className="roller" />
      </div>
    </div>
    {props.previewUrl && <div className="loader-bg">
      <img src={props.previewUrl} alt="." />
    </div>}
    {false && !props.previewUrl && <div className="loader-text">
      <p dangerouslySetInnerHTML={{__html: i18n.t("processing2_0")}} />
      <ul>
          <li dangerouslySetInnerHTML={{__html: i18n.t("processing2_1")}} />
          <li dangerouslySetInnerHTML={{__html: i18n.t("processing2_2")}} />
          <li dangerouslySetInnerHTML={{__html: i18n.t("processing2_3")}} />
          <li dangerouslySetInnerHTML={{__html: i18n.t("processing2_4")}} />
      </ul>
    </div>}
  </React.Fragment>;
}
