export default class PhotolabTaskImageUrl {

  constructor(url, rect, rotation, flip, altBody) {
    this._url = url;
    this._rect = rect || "";
    this._rotation = rotation || 0;
    this._flip = flip || 0;
    this._altBody = altBody || "";
  }

  get url() {
    return this._url;
  }

  get rect() {
    return this._rect;
  }

  get rotation() {
    return this._rotation;
  }

  get flip() {
    return this._flip;
  }

  get altBody() {
    return this._altBody;
  }
}