import {generatePath} from "react-router";
import routes from "../routes";
import * as api from "./api";
import {hitEvent, hits} from "./log";

export function goToProcessing(history, file, maskFile = null, forkProcessing = false) {
  if (window.clientConfig.isWebviewIOS || true) {
    const processingUrl = new URL(window.location.href);
    processingUrl.pathname = generatePath(routes.PROCESSING, {hash: Date.now()});
    processingUrl.searchParams.append("file_url", encodeURIComponent(file.url));

    if (maskFile) {
      processingUrl.searchParams.append("mask_file_url", encodeURIComponent(maskFile.url));
    }

    if (forkProcessing) {
      processingUrl.searchParams.append("fork_processing", "1");
    }

    Object.keys(window.clientConfig.webviewParams).forEach((key) => {
      processingUrl.searchParams.append(key, window.clientConfig.webviewParams[key]);
    });

    api.getBuildInfo()
      .then((result) => {
        if (parseInt(window.appConfig.build.version) < parseInt(result.version)) {
          window.location.replace(processingUrl.toString());
          hitEvent(hits.FORCE_REDIRECT_PROCESSING);
        } else {
          history.replace({
            pathname: generatePath(routes.PROCESSING),
            state: {file, maskFile, forkProcessing},
          });
        }
      })
      .catch((err) => {
        window.location.replace(processingUrl.toString());
        hitEvent(hits.FORCE_REDIRECT_PROCESSING);
      });

    return;
  }

  history.replace({
    pathname: generatePath(routes.PROCESSING),
    state: {file, maskFile, forkProcessing},
  });
}

