import {getHandlerByName} from "./handlers";
import Processing from "./Processing";
import {
  hitEvent,
  hits,
  logEvent,
  logEventNative,
  userEvents, userNativeEvents
} from "../utils/log";
import * as api from "../utils/api";
import Creative from "./Creative";
import groups from "./config/groups";
import {extractTemplateIdFromXml} from "./api";
import {debounce, throttle} from "../utils/etc";
import {creativeName} from "../utils/creative";

const localStorageKey = "npp:processing";

export class ProcessingManager {

  _listeners = [];
  _processing = null;
  _handlers = [];

  /** @return {Processing} */
  get processing() {
    return this._processing;
  }

  /** @param {Processing} processing */
  start = (processing) => {
    this.stop();

    this._processing = processing;

    if (!processing.hasExtra(Processing.EXTRA_STARTED_AT)) {
      processing.setExtra(Processing.EXTRA_STARTED_AT, Date.now());
    }

    window.localStorage.setItem(localStorageKey, processing.toJSON());

    this.update();

    this.removeOnProcessingChangeHandler(this.handleCheckGlossGroup);
    this.addOnProcessingChangeHandler(this.handleCheckGlossGroup);
  };

  update = () => {
    if (!this.processing) {
      return;
    }

    const processing = this.processing;

    processing.creatives.forEach((creative) => {
      if (creative.getExtra(Creative.EXTRA_KEEP_PENDING, false) === true) {
        return;
      }

      if (creative.isFinished) {
        return;
      }

      const isActive = this._handlers.findIndex((item) => item.id === creative.id) > -1;
      if (isActive) {
        return;
      }

      const handler = getHandlerByName(creative.handler);
      if (handler === null) {
        throw new Error("Unrecognized handler name: " + creative.handler);
      }

      if (!creative.hasExtra(Creative.EXTRA_STARTED_AT)) {
        creative.setExtra(Creative.EXTRA_STARTED_AT, Date.now());

        logEvent(userEvents.CREATIVE_STARTED, {
          group: creative.group,
          template_id: creativeName(creative),
        });
      }

      if (window.clientConfig.features.isAutoStartCreatives) {
        hitEvent(hits.START_AUTO_PROCESSING, 1, true);
      } else {
        hitEvent(hits.START_PENDING_PROCESSING, 1, true);
      }

      const handlerPromise = handler(processing, creative)
        .then((creative) => {
          this.tick();

          logEvent(userEvents.CREATIVE_PROCESSED, {
            group: creative.group,
            template_id: creativeName(creative),
            processing_time: Date.now() - creative.getExtra(Creative.EXTRA_STARTED_AT),
          });

          logEventNative(userNativeEvents.templateApplied, [
            processing.getCreativePosition(creative) + 1,
            creative.group,
            creative.isRefreshed ? "0" : "1",
            processing.getExtra(Processing.EXTRA_IS_FORKED, false) ? 0 : 1,
            "",
            creativeName(creative),
          ]);
        })
        .catch((creative) => {
          this.tick();
          logEvent(userEvents.CREATIVE_FAILED, {
            group: creative.group,
            template_id: creativeName(creative),
            processing_time: Date.now() - creative.getExtra(Creative.EXTRA_STARTED_AT),
            error: creative.error,
            photo: processing.file.url,
          });
        });

      this._handlers.push({
        id: creative.id,
        handler: handlerPromise,
      });
    });

    this.tick();
  };

  /** @return {Processing} */
  restore = () => {
    const storedValue = window.localStorage.getItem(localStorageKey);
    if (storedValue != null) {
      const processing = new Processing();
      processing.fromObject(JSON.parse(storedValue));

      return processing;
    }

    return null;
  };

  stop = () => {
    this._processing = null;
  };

  clear = () => {
    window.localStorage.removeItem(localStorageKey);
    this.stop();
  };

  tickImmediately = () => {
    if (!this.processing) {
      return;
    }

    this.commit(this.processing.toJSON());

    this._listeners.forEach((listener) => {
      listener.call(null, this.processing);
    });
  };

  tick = throttle(200, this.tickImmediately);

  commit = (data, commitToApi = false) => {
    window.localStorage.setItem(localStorageKey, data);

    if (commitToApi) {
      this.commitToApi(data);
    }
  };

  commitToApi = (data) => {
    clearTimeout(this.commitTimer);
    this.commitTimer = setTimeout(() => {
      api.processingCommit(data).then(() => {
        console.info("Processing committed");
      }).catch((err) => {
        console.error("Processing commit is failed", err);
      });
    }, 2000);
  };

  addOnProcessingChangeHandler = (listener) => {
    this._listeners.push(listener);
  };

  removeOnProcessingChangeHandler = (listener) => {
    const pos = this._listeners.indexOf(listener);
    if (pos >= 0) {
      this._listeners.splice(pos, 1);
    }
  };

  handleCheckGlossGroup = () => {
    if (!this.processing) {
      return;
    }

    const glossFailedCreatives = this.processing.getCreativesInGroup(groups.GLOSS)
      .filter((c) => {
        const taskConfig = c.getTaskConfig("s0");

        if (taskConfig) {
          const templateId = extractTemplateIdFromXml(taskConfig);

          return parseInt(templateId) === 7000 && c.isFailed;
        }

        return false;
      });

    const celebrityProcessedCreatives = this.processing.getCreativesInGroup(groups.CELEBRITY)
      .filter((c) => c.isProcessed);

    if (glossFailedCreatives.length > 0 && celebrityProcessedCreatives.length > 0) {
      debounce("ProcessingManager_handleCheckGlossGroup", 5000, () => {
        this.commitToApi(this.processing.toJSON());
      });
    }
  }
}

export default new ProcessingManager();