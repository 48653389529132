import React from 'react';
import routes from "../routes";
import {
  hitEvent,
  hits,
  logEvent,
  logEventNative,
  logProcessingsTimings,
  userEvents, userNativeEvents
} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {fileToJson} from "../utils/webview";
import {
  getProcessingGroups,
  getCreativesConfigs,
  resolveCreativeConfigByGroup, getCreativesConfigsByGroup,
} from "../photolab/config";
import creativeGroups from "../photolab/config/groups";
import Processing from "../photolab/Processing"
import Creative from "../photolab/Creative";
import {createMd5Token} from "../utils/text";
import ErrorView from "../components/ErrorView";
import {resetNativeAdsCounter, showNativeAds} from "../utils/native-ads";
import clientStorage from "../utils/client-storage";
import {resolveCreativePreviewFile} from "../utils/creative";
import ResubscribeScreenView from "../components/ResubscribeScreenView";
import {getFullSizeCreatives} from "../photolab/config/config-generic";
import {webviewCall, webviewShowBanner} from "../utils/webview";
import processingManager from "../photolab/ProcessingManager";
import {debounce} from "../utils/etc";

export default class ProcessingPage extends React.Component {

  state = {
    error: null,
    isShowResubscribeView: false,
  };

  processingTimerId = null;
  processingTimer = 0;

  componentDidMount() {
    document.addEventListener("visibilitychange", this.startProcessingTimer, false);
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);

    this.setState({
      isShowResubscribeView: window.clientConfig.features.showResubscribeView,
    });

    logEvent(userEvents.PAGE, {page: "processing"});

    const loadedUrl = new URL(window.location.href);
    const fileUrlParam = loadedUrl.searchParams.get("file_url");
    const maskFileUrlParam = loadedUrl.searchParams.get("mask_file_url");
    const forkProcessingParam = loadedUrl.searchParams.get("fork_processing");
    const locationState = this.props.location.state || {};

    const file = (fileUrlParam && fileToJson(decodeURIComponent(fileUrlParam)))
      || locationState.file;

    const maskFile = (maskFileUrlParam && fileToJson(decodeURIComponent(maskFileUrlParam)))
      || locationState.maskFile;

    const doForkProcessing = (forkProcessingParam === "1")
      || locationState.forkProcessing;

    const processing = processingManager.restore();

    if (processing) {
      window.clientConfig.features.showResubscribeView
        ? this.context.hideLoader()
        : this.context.showLoader(doForkProcessing, processing.file.url);
      if (doForkProcessing) {
        this.forkProcessing(processing, file, maskFile);
      } else {
        processingManager.start(processing);
      }
    } else if (file) {
      window.clientConfig.features.showResubscribeView
        ? this.context.hideLoader()
        : this.context.showLoader(true, file.url);

      this.startProcessing(file, maskFile);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    this.stopProcessingTimer();

    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  stopProcessingTimer = () => {
    clearInterval(this.processingTimerId);
    document.removeEventListener("visibilitychange", this.startProcessingTimer, false);
  }

  startProcessingTimer = () => {
    clearInterval(this.processingTimerId);

    if (document.visibilityState !== "visible") {
      return;
    }

    this.processingTimerId = setInterval(() => {
      this.processingTimer++;

      if ((this.processingTimer * 1000) > window.appConfig.processings.timeout) {
        this.stopProcessingTimer();
        this.handleProcessingTimeout();
      }
    }, 1000);
  }

  startProcessing = (file, maskFile) => {
    const groups = getProcessingGroups();

    const processing = new Processing();
    processing.setId(Date.now());
    processing.setFile(file);
    processing.setMaskFile(maskFile);
    processing.setGroups(groups);
    processing.setLanguage(window.clientConfig.lang);
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());

    getCreativesConfigs()
      .filter((config) => config.group === creativeGroups.COMMON)
      .forEach((config) => {
        processing.addCreative(new Creative()
          .configureByConfig(config));
      });

    const sessionId = parseInt(window.clientConfig.webviewParams.session_idx || 0);
    const [storedSessionId, storedCount] = clientStorage.getCountProcessingInSession();
    const processingNumber = sessionId !== storedSessionId ? 1 : (storedCount + 1);
    clientStorage.setCountProcessingInSession(sessionId, processingNumber);

    let doAddFullSizeCreative = false;
    if (window.clientConfig.features.showFullSizeBanner) {
      if (window.clientConfig.features.showFullSizeBannerDisabledByRegion) {
        doAddFullSizeCreative = false;
      } else {
        doAddFullSizeCreative = processingNumber === 1;
      }
    }

    if (doAddFullSizeCreative) {
      getFullSizeCreatives().forEach((config) => {
        processing.addCreative(new Creative().configureByConfig(config));
      });
    }

    processingManager.start(processing);

    this.startProcessingTimer();

    if (!doAddFullSizeCreative) {
      showNativeAds();
    }
  };

  /** @param {Processing} processing */
  addCreatives = (processing) => {
    const startKeepPendingIndex = (window.clientConfig.splitGroupId < 11) ? 1 : 0; // true

    processing.groups.forEach((group, index) => {
      const selectedCreativeConfig = resolveCreativeConfigByGroup(group, processing.getGender());
      if (!selectedCreativeConfig) {
        return;
      }

      const selectedCreative = new Creative()
        .configureByConfig(selectedCreativeConfig)
        .setAsSelected(true);

      const addCreatives = [];
      addCreatives.push(selectedCreative);

      getCreativesConfigsByGroup(group)
        .filter((c) => c.templateId !== selectedCreativeConfig.templateId)
        .forEach((creativeConfig) => {
          addCreatives.push(new Creative().configureByConfig(creativeConfig));
        })

      addCreatives.forEach((c, ci) => {
        let keepPending = !window.clientConfig.isPro;
        if (c.isSelected) {
          keepPending = false;

          if (!window.clientConfig.features.isAutoStartCreatives && !window.clientConfig.isPro) {
            keepPending = index > startKeepPendingIndex;
          }
        }

        if (keepPending) {
          c.setExtra(Creative.EXTRA_KEEP_PENDING, true);
        }

        c.setAsRefreshed(ci > 0);

        processing.addCreative(c);

        const creativePreviewImageUrl = resolveCreativePreviewFile(c);
        if (creativePreviewImageUrl) {
          (new Image()).src = creativePreviewImageUrl;
        }
      });
    });

    processingManager.update();
  }

  forkProcessing = (currentProcessing, file, maskFile) => {
    processingManager.clear();

    const forkedProcessing = new Processing();
    forkedProcessing.setId(Date.now());
    forkedProcessing.setFile(file);
    forkedProcessing.setMaskFile(maskFile);
    forkedProcessing.setGroups(currentProcessing.groups);
    forkedProcessing.setLanguage(currentProcessing.language);

    Object.keys(currentProcessing.extra).forEach((key) => {
      forkedProcessing.setExtra(key, currentProcessing.getExtra(key));
    });

    forkedProcessing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    forkedProcessing.setExtra(Processing.EXTRA_IS_FORKED, true);

    currentProcessing.creatives.forEach((creative) => {
      if (creative.group === creativeGroups.COLLAGE && !creative.isSelected) {
        return;
      }

      const data = JSON.parse(JSON.stringify(creative.data));
      data.status = Creative.STATUS_PENDING;
      data.tasks = {};
      data.files = {};
      data.error = null;
      data.result = null;
      data.id = createMd5Token(Date.now(), data.group, data.templateId, data.handler);

      if (creative.group === creativeGroups.COLLAGE) {
        delete data.extra[Creative.EXTRA_CHILDREN_HASH];
        delete data.extra[Creative.EXTRA_CHILDREN_IDS];
      }

      const forkedCreative = new Creative().fromObject(data);

      forkedProcessing.addCreative(forkedCreative);
    });

    processingManager.start(forkedProcessing);

    this.startProcessingTimer();

    logEventNative(userNativeEvents.maskEdited);
  };

  /** @param {Processing} processing */
  handleProcessingChange = (processing) => debounce("ProcessingPage_handleProcessingChange", 100, () => {
    if (window.appConfig.isDebug) {
      console.info("ProcessingPage::handleProcessingChange", JSON.parse(processing.toJSON()));
    }

    const selectedAndStartedCreatives = processing.creatives.filter((c) => {
      return c.isSelected
        && c.getExtra(Creative.EXTRA_KEEP_PENDING, false) !== true
        && c.group !== creativeGroups.COMMON
        && c.group !== creativeGroups.FULL_SIZE
        && c.group !== creativeGroups.COLLAGE
      ;
    });

    const genderCreative = processing.creatives.find((c) => {
      return c.group === creativeGroups.COMMON && c.templateId === "gender";
    });

    if (genderCreative.isPending) {
      return;
    }

    let fullSizeCreative = processing.getCreativesInGroup(creativeGroups.FULL_SIZE).first();
    if (fullSizeCreative) {
      if (fullSizeCreative.isFinished) {
        processingManager.processing.removeCreative(fullSizeCreative);
      }

      if (fullSizeCreative.isProcessed) {
        webviewShowBanner("pre_result",
          showNativeAds,
          {result_url: encodeURIComponent(fullSizeCreative.getFile("raw"))},
        );
      }
    }

    if (selectedAndStartedCreatives.length === 0) {
      this.addCreatives(processing);
      return;
    }

    const processedCreatives = selectedAndStartedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = selectedAndStartedCreatives.filter((c) => c.isFailed);

    if (processedCreatives.length > 0) {
      this.stopProcessingTimer();

      clientStorage.incrementProcessedPhotosAmount();
      if (!clientStorage.hasFirstProcessingProcessedAt()) {
        clientStorage.setFirstProcessingProcessedAt(Date.now());
      }

      const elapsedMs = Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT);

      hitEvent(hits.PROCESSING_PROCESSED);
      logEvent(userEvents.PROCESSING_PROCESSED, {elapsed_time_ms: elapsedMs});
      logProcessingsTimings(elapsedMs);

      this.props.history.replace({pathname: routes.RESULT});
    }
    else if (failedCreatives.length === selectedAndStartedCreatives.length) {
      this.stopProcessingTimer();

      const failedCreative = failedCreatives[0];

      hitEvent(hits.PROCESSING_FAILED);
      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT),
      });

      if (failedCreative.error && failedCreative.error.type === "photolab") {
        hitEvent(hits.PROCESSING_FAILED_BY_PHOTOLAB);
        resetNativeAdsCounter();
      } else {
        // processingManager.commitToApi(processing.toJSON());
      }

      processingManager.clear();

      this.setState({
        error: failedCreative.error,
      }, this.context.hideLoader);
    }
  });

  handleFileSelected = (file) => {
    logEvent(userEvents.PHOTO_SELECT, {page: "processing"});

    this.context.showLoader(true, null, () => {
      this.props.history.replace(routes.UPLOAD, {file});
    });
  };

  handleProcessingTimeout = () => {
    processingManager.clear();

    hitEvent(hits.PROCESSING_TIMEOUT);

    this.setState({
      error: {
        type: "processing_timeout",
        code: 1,
        message: "timeout",
      },
    }, this.context.hideLoader);
  };

  handleFullSizeDeeplinkClick = () => {
    logEvent(userEvents.FULL_SIZE_RESULTS_CLICK, {page: "processing"});

    if (window.clientConfig.features.showResubscribeView) {
      if (!window.clientConfig.subscriptionSku) {
        console.error("No 'subs_id' parameter passed");
      } else {
        webviewCall("inapp", {id: window.clientConfig.subscriptionSku});
      }
    } else {
      webviewShowBanner("postdownload");
    }

    setTimeout(() => {
      this.handleRemoveFullSizeCreative();
    }, 300);
  }

  handleRemoveFullSizeCreative = () => {
    processingManager.processing.removeCreative(this.state.fullSizeCreative);

    this.setState({
      fullSizeCreative: null,
    }, () => {
      processingManager.update();
    });
  }

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onFileSelected={this.handleFileSelected}
      />;
    }

    if (this.state.isShowResubscribeView) {
      return <ResubscribeScreenView />;
    }

    return <React.Fragment />;
  }
}

ProcessingPage.contextType = AppContext;
