import React from "react";
import PropTypes from "prop-types";

export default class CountdownLoaderSvg extends React.Component {

  state = {
    path: "M 0 0 v -50 A 50 50 1 1 1 0 0 z",
  };

  componentDidMount() {
    this.animate();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  animate = () => {
    this.progress = this.progress || 0;
    this.progress++;
    this.progress %= 360;

    const r = (this.progress * Math.PI / 180);
    const x = Math.sin(r) * 50;
    const y = Math.cos(r) * -50;
    const m = (this.progress > 180) ? 1 : 0;

    this.setState({
      path: `M 0 0 v -50 A 50 50 1 ${m} 1 ${x} ${y} z`,
    });

    this.timer = setTimeout(this.animate, (this.props.duration/360 * 1000));
  }

  render() {
    return <svg className="rotate" viewBox={`0 0 100 100`}>
      <path d={this.state.path} />
    </svg>
  }
}

CountdownLoaderSvg.propTypes = {
  duration: PropTypes.number.isRequired,
}