const VERSION = 1;

const KEY_PREFS = "npp:prefs";
const KEY_PREFS_VERSION = "npp:prefs:version";

const KEY_PRO_WATERMARK_TOOLTIP_IS_HIDDEN = "pro_watermark_tooltip_is_hidden";

class ClientSessionStorage {

  constructor() {
    const prefs = window.sessionStorage.getItem(KEY_PREFS);
    if (prefs) {
      this.prefs = JSON.parse(prefs);
    } else {
      this.prefs = {};
    }

    const currentVersion = parseInt(window.sessionStorage.getItem(KEY_PREFS_VERSION) || 1);
    if (currentVersion !== VERSION) {
      this.migrate(currentVersion, VERSION);
      window.sessionStorage.setItem(KEY_PREFS_VERSION, "" + VERSION);
    }
  }

  migrate(fromVersion, toVersion) {
    // ...
  }

  commit() {
    window.sessionStorage.setItem(KEY_PREFS, JSON.stringify(this.prefs));
  }

  setBoolean(key, value) {
    this.prefs[key] = !!value;
    this.commit();
  }

  getBoolean(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return !!this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  setInteger(key, value) {
    this.prefs[key] = parseInt(value);
    this.commit();
  }

  getInteger(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return parseInt(this.prefs[key]);
    } else {
      return defaultValue;
    }
  }

  setString(key, value) {
    this.prefs[key] = "" + value;
    this.commit();
  }

  getString(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return "" + this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  // ---
  getProWatermarkTooltipIsHidden() {
    return this.getBoolean(KEY_PRO_WATERMARK_TOOLTIP_IS_HIDDEN, false);
  }

  setProWatermarkTooltipIsHidden(value) {
    this.setBoolean(KEY_PRO_WATERMARK_TOOLTIP_IS_HIDDEN, value);
  }
}

export default new ClientSessionStorage();