import React from "react";
import PropTypes from "prop-types";
import i18n from "../i18n";

const unitType = {
  days: "days",
  hours: "hours",
  minutes: "minutes",
  seconds: "seconds",
};

export default class CountdownTimer extends React.Component {

  state = {
    days: 0,
    daysIsUpdated: false,
    hours: 0,
    hoursIsUpdated: false,
    minutes: 0,
    minutesIsUpdated: false,
    seconds: 0,
    secondsIsUpdated: false,
  };

  componentDidMount() {
    this.tick();
    this.timerId = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  getDiff = () => {
    const leftSeconds = Math.ceil((this.props.targetMillis - Date.now()) / 1000);

    const diff = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (leftSeconds > 0) {
      diff.days = Math.floor(leftSeconds / 86_400);
      diff.hours = Math.floor((leftSeconds % 86_400) / 3_600);
      diff.minutes = Math.floor(((leftSeconds % 86_400) % 3_600) / 60);
      diff.seconds = Math.floor(leftSeconds % 60);
    }

    return diff;
  };

  tick = () => {
    const diff = this.getDiff();
    const nextState = {...diff};

    nextState.daysIsUpdated = nextState.days !== this.state.days;
    nextState.hoursIsUpdated = nextState.hours !== this.state.hours;
    nextState.minutesIsUpdated = nextState.minutes !== this.state.minutes;
    nextState.secondsIsUpdated = nextState.seconds !== this.state.seconds;

    this.setState(nextState, () => {
      const countDown = Object.values(diff).reduce((a, b) => a + b);

      if (countDown === 0) {
        clearInterval(this.timerId);
        this.props.onExpire && this.props.onExpire();
      }
    });
  };

  render() {
    return <div className="countdown-timer">
      <TimerUnit
        unit={unitType.days}
        value={this.state.days}
        isUpdated={this.state.daysIsUpdated} />
      <TimerUnit
        unit={unitType.hours}
        value={this.state.hours}
        isUpdated={this.state.hoursIsUpdated} />
      <TimerUnit
        unit={unitType.minutes}
        value={this.state.minutes}
        isUpdated={this.state.minutesIsUpdated} />
      <TimerUnit
        unit={unitType.seconds}
        value={this.state.seconds}
        isUpdated={this.state.secondsIsUpdated} />

      {/*{this.state.seconds}*/}
    </div>;
  }
}

CountdownTimer.propTypes = {
  targetMillis: PropTypes.number.isRequired,
};

class TimerUnit extends React.Component {

  render() {

    let nextValue = this.props.value - 1;
    if (nextValue < 0) {
      if (this.props.unit === unitType.days) {
        nextValue = 99;
      } else if (this.props.unit === unitType.hours) {
        nextValue = 23;
      } else {
        nextValue = 59;
      }
    }

    let prevValue = this.props.value + 1;
    if (this.props.unit === unitType.days && prevValue > 99) {
      prevValue = 99;
    } else if (this.props.unit === unitType.hours && prevValue > 23) {
      prevValue = 0;
    } else if (prevValue > 59) {
      prevValue = 0;
    }

    const value = pad(this.props.value);
    const leftDigit = parseInt(value[0]);
    const rightDigit = parseInt(value[1]);

    nextValue = pad(nextValue);
    const nextLeftDigit = parseInt(nextValue[0]);
    const nextRightDigit = parseInt(nextValue[1]);

    prevValue = pad(prevValue);
    const prevLeftDigit = parseInt(prevValue[0]);
    const prevRightDigit = parseInt(prevValue[1]);

    return <div className="countdown-timer__unit">
      <div className="countdown-timer__cards_container">
        <TimerUnitCard
          digit={leftDigit}
          nextDigit={nextLeftDigit}
          prevDigit={prevLeftDigit}
          isUpdated={this.props.isUpdated} />
        <TimerUnitCard
          digit={rightDigit}
          nextDigit={nextRightDigit}
          prevDigit={prevRightDigit}
          isUpdated={this.props.isUpdated} />
      </div>
      <div className="countdown-timer__unit_label">
        <span dangerouslySetInnerHTML={{__html: i18n.t("timer__" + this.props.unit, {count: this.props.value})}} />
      </div>
    </div>
  }
}

class TimerUnitCard extends React.Component {

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.digit !== this.props.digit;
  }

  render() {
    return <div className="countdown-timer__card">
      <div className="countdown-timer__card--upper">
        <span>{this.props.digit}</span>
      </div>
      <div className="countdown-timer__card--lower">
        <span>{this.props.digit}</span>
      </div>
      {/*<div className={`countdown-timer__card--flip ${this.props.isUpdated ? "fold" : "unfold"}`}>*/}
      {/*  <span>{this.props.nextDigit}</span>*/}
      {/*</div>*/}
      {/*<div className={`countdown-timer__card--flip ${this.props.isUpdated ? "unfold" : "fold"}`}>*/}
      {/*  <span>{this.props.digit}</span>*/}
      {/*</div>*/}
    </div>;
  }
}

function pad(value) {
  value = ("0" + value);

  return value.substring(value.length - 2);
}