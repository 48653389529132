import * as Sentry from "@sentry/react";

const forceReportTokens = [
  /776B2A5B-792C-4279-999B-D059240AD73D/i,
];

if (window.appConfig.sentry.isEnabled) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: window.appConfig.sentry.dsn,
    sampleRate: window.appConfig.sentry.sampleRate,
    tracesSampler: (samplingContext) => {
      if (forceReportTokens.find((t) => t.test(window.clientConfig.token))) {
        return true;
      } else {
        return window.appConfig.sentry.sampleRate;
      }
    },
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });

  Sentry.setUser({id: window.clientConfig.token});
  Sentry.setTag("client_type", window.clientConfig.isWebview ? "webview" : "web");
  Sentry.setTag("client_split_group", window.clientConfig.splitGroupId);
  Sentry.setTag("client_is_pro", window.clientConfig.isPro);
  Sentry.setTag("client_is_new", window.clientConfig.isNew);
  Sentry.setTag("build_version", window.appConfig.build.version);
}