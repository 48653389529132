import Modal from "./Modal";
import React from "react";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";
import {hitEvent, hits} from "../utils/log";

export default class WatermarkModal extends Modal {

  constructor(props) {
    super(props);
    this.className = ""; // todo
  }

  handleCancelButtonClick = () => {
    hitEvent(hits.WATERMARK_POPUP_KEEP_CLICK);

    this.props.onCancelButtonClick();
    this.dismiss();
  };

  handleRemoveButtonClick = () => {
    hitEvent(hits.WATERMARK_POPUP_REMOVE_CLICK);

    this.props.onRemoveButtonClick();
    this.dismiss();
  };

  renderModal() {
    return <div className="modal-container">
      <div className="modal modal_rate">
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("remove_logo_modal__message")}}></p>
        <button
          className="rate-button"
          children={i18n.t("remove_logo_modal__btn_remove")}
          onClick={this.handleRemoveButtonClick} />
        <button
          className="skip-button"
          children={i18n.t("remove_logo_modal__btn_cancel")}
          onClick={this.handleCancelButtonClick} />
      </div>
    </div>
  }
}

WatermarkModal.contextType = AppContext;
